/* eslint-disable import/order, no-unused-vars */
import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate, useParams } from 'react-router-dom';
import { useLazyQuery, useQuery } from '@apollo/client';
import { isEmpty } from 'lodash';
import { Box, Tab, Typography } from '@mui/material';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  CallList, CallListSkeleton, HeaderSkeleton,
} from '../../components';
import { ReactComponent as DrawerIcon } from '../../assets/icons/draverIcon.svg';
import { ReactComponent as AltArrowLeftIcon } from '../../assets/icons/altArrowLeftIcon.svg';
import { ALL_TRANSCRIPTS_QUERY, TRANSCRIPT_QUERY } from '../../queries/transcript/transcript';
import { MY_ACCOUNT_SETTINGS } from '../../queries/auth/account';
import {
  TranscriptsListType, TranscriptType, HighlightType,
} from '../../shared/types/transcript';
import { handleResizeWindow, calculateWidth } from '../../shared/utils/calculateWidth';
import CallDetailsSkeleton from '../../components/Skeletons/CallDetailsSkeleton';
import TabEmptyState from '../../components/EmptyStates/TabEmptyState';
import HeaderEmptyState from '../../components/EmptyStates/HeaderEmptyState';
import CallNotFound from '../../components/CallNotFound';
import Download from '../../components/Download';
import MoveToTopButton from '../../components/MoveToTopButton';
import { formatDateTimeToUserTimezone } from '../../shared/utils/dateHelpers';
import ResearchCallDetails from './components/ResearchCallDetails';
import ContentTabPanel from '../../components/Tabs/ContentTabPanel';
import DrawerHeader from '../../layouts/MainApp/components/DrawerHeader';
import AddCall from '../../components/AddCall';
import ResearchAppHeaderLayout from '../../layouts/ResearchAppHeaderLayout';
import ScheduledCallList from '../../layouts/MainApp/components/scheduledCallList';
import {
  Main, DrawerContainer, DrawerMobile, HeaderContainer, HeaderMobile, ScrollContainer,
  // DrawerHeader,
  DrawerButton, VerticalDivider, ContentTabs, contentA11yProps,
} from './components/styledComponents';
import { AppContext, useMe } from '../../components/AppContextProvider';
import paths from '../../paths/paths';
import FiltersSection from '../../layouts/MainApp/components/FiltersSection';
import useFilterLogic from '../../layouts/MainApp/filterLogic';

window.addEventListener('resize', handleResizeWindow);

const QUERY_INITIAL_VALUE = { results: [], totalCount: 0 };

type FetchMoreFunction = (options: {
  variables: {
    skip: number
  }
  updateQuery: (
    prev: TranscriptsListType,
    { fetchMoreResult }: { fetchMoreResult: TranscriptsListType }
  ) => TranscriptsListType
}) => void

const ResearchAppCalls = () => {
  const { me } = useMe();
  const { transcriptId: transcriptUuid } = useParams<{ transcriptId?: string | undefined }>();
  const [transcriptId, setTranscriptId] = useState<string | undefined>(transcriptUuid);
  const navigate = useNavigate();
  const { data: { myAccountSettings: settings = {} } = {} } = useQuery(MY_ACCOUNT_SETTINGS);
  const [searchQuery, setSearchQuery] = useState('');

  const [open, setOpen] = useState(true);
  const [openDrawerMobile, setOpenDrawerMobile] = useState(true);
  const [selectedHighlight, setSelectedHighlight] = useState<HighlightType | null>(null);
  const [tabIndex, setSelectedTabIndex] = useState(2);
  const [audio, setAudio] = useState<HTMLAudioElement | null>(null);
  const [isPlayingMain, setIsPlayingMain] = useState(false);
  const [isPlayingParagraph, setIsPlayingParagraph] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const filterLogic = useFilterLogic();

  const toggleDrawer = () => {
    setOpen(!open);
    setTimeout(() => {
      calculateWidth();
    }, 500);
  };

  const [getTranscript, {
    data: { transcript = {} } = {},
    loading: transcriptLoading,
    refetch: refetchTranscript,
    error: isNotFound,
  }] = useLazyQuery(TRANSCRIPT_QUERY, { fetchPolicy: 'network-only' });
  const transcriptWithType: TranscriptType = transcript;
  const audioUrl = transcriptWithType?.call?.transcript?.audioUrl;

  const {
    data: { allTranscripts: allScheduledTranscripts = QUERY_INITIAL_VALUE } = {},
    loading: scheduledLoading,
    fetchMore: fetchMoreScheduled,
    refetch: refetchScheduledTab,
  } = useQuery<TranscriptsListType>(ALL_TRANSCRIPTS_QUERY, {
    variables: {
      searchQuery,
      analysts: filterLogic.analysts.map(a => a.uuid),
      acaIoiAccountsUuids: filterLogic.acaIoiAccounts.map(a => a.uuid),
      networks: filterLogic.networks.map(n => n.uuid),
      tickers: filterLogic.tickers.map(t => t.uuid),
      displayInResearchPortal: filterLogic.displayInResearchPortal,
      scheduledTab: true,
      dateRangeStart: filterLogic.dateRangeStart,
      dateRangeEnd: filterLogic.dateRangeEnd,
      first: 10,
      skip: 0,
    },
  });

  const {
    data: { allTranscripts: allReviewedTranscripts = QUERY_INITIAL_VALUE } = {},
    loading: reviewedLoading,
    fetchMore: fetchMoreReviewed,
    refetch: refetchReviewedTab,
  } = useQuery<TranscriptsListType>(ALL_TRANSCRIPTS_QUERY, {
    variables: {
      searchQuery,
      analysts: filterLogic.analysts.map(a => a.uuid),
      complianceReviewerUuids: filterLogic.complianceReviewers.map(c => c.uuid),
      acaIoiAccountsUuids: filterLogic.acaIoiAccounts.map(a => a.uuid),
      networks: filterLogic.networks.map(n => n.uuid),
      flags: filterLogic.flags.map(f => f.uuid),
      tickers: filterLogic.tickers.map(t => t.uuid),
      displayInResearchPortal: filterLogic.displayInResearchPortal,
      reviewStatus: 'reviewed',
      dateRangeStart: filterLogic.dateRangeStart,
      dateRangeEnd: filterLogic.dateRangeEnd,
      first: 10,
      skip: 0,
    },
    fetchPolicy: 'network-only',
  });

  const handleOnSearch = useCallback((search: string) => {
    setSearchQuery(search);
  }, []);

  const handleOnBack = async () => {
    setSearchQuery('');
  };

  const [allCompletedTranscripts, setAllCompletedTranscripts] = useState<{
    totalCount: number
    results: TranscriptType[]
  }>(QUERY_INITIAL_VALUE);

  const {
    loading: completedLoading,
    refetch: refetchCompletedTab,
    fetchMore: fetchMoreCompleted,
  } = useQuery<TranscriptsListType>(ALL_TRANSCRIPTS_QUERY, {
    variables: {
      searchQuery,
      analysts: filterLogic.analysts.map(a => a.uuid),
      complianceReviewerUuids: filterLogic.complianceReviewers.map(c => c.uuid),
      acaIoiAccountsUuids: filterLogic.acaIoiAccounts.map(a => a.uuid),
      flags: filterLogic.flags.map(f => f.uuid),
      networks: filterLogic.networks.map(n => n.uuid),
      tickers: filterLogic.tickers.map(t => t.uuid),
      displayInResearchPortal: filterLogic.displayInResearchPortal,
      completedTab: true,
      dateRangeStart: filterLogic.dateRangeStart,
      dateRangeEnd: filterLogic.dateRangeEnd,
      first: 10,
      skip: 0,
    },
    onCompleted: data => setAllCompletedTranscripts(data.allTranscripts),
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const [hasMoreScheduled, setHasMoreScheduled] = useState(false);
  const [hasMoreCompleted, setHasMoreCompleted] = useState(false);
  const [hasMoreReviewed, setHasMoreReviewed] = useState(false);

  useEffect(() => {
    setHasMoreScheduled(allScheduledTranscripts.results.length < allScheduledTranscripts.totalCount);
    setHasMoreReviewed(allReviewedTranscripts.results.length < allReviewedTranscripts.totalCount);
    setHasMoreCompleted(allCompletedTranscripts.results.length < allCompletedTranscripts.totalCount);
  }, [
    allCompletedTranscripts.results,
    allCompletedTranscripts.totalCount,
    allReviewedTranscripts.results,
    allReviewedTranscripts.totalCount,
    allScheduledTranscripts.results,
    allScheduledTranscripts.totalCount,
  ]);

  const loadMoreTranscripts = (
    fetchMoreFunction: FetchMoreFunction,
    hasMoreFlag: boolean,
    allTranscripts: TranscriptsListType['allTranscripts'],
  ) => {
    if (!hasMoreFlag) return;

    fetchMoreFunction({
      variables: { skip: allTranscripts.results.length },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;

        const prevResults = prev.allTranscripts.results;
        const newResults = fetchMoreResult.allTranscripts.results;

        const combinedResults = [
          ...prevResults,
          ...newResults.filter(
            newResult => !prevResults.some(prevResult => prevResult.uuid === newResult.uuid),
          ),
        ];

        return {
          allTranscripts: {
            ...prev.allTranscripts,
            results: combinedResults,
          },
        };
      },
    });
  };

  const loadMoreCompleted = () => {
    loadMoreTranscripts(fetchMoreCompleted, hasMoreCompleted, allCompletedTranscripts);
  };

  const loadMoreScheduled = () => {
    loadMoreTranscripts(fetchMoreScheduled, hasMoreScheduled, allScheduledTranscripts);
  };

  const loadMoreReviewed = () => {
    loadMoreTranscripts(fetchMoreReviewed, hasMoreReviewed, allReviewedTranscripts);
  };

  const {
    setRefetchCompletedCalls,
    setRefetchReviewedCalls,
    setRefetchScheduledCalls,
    setRefetchTranscript,
  } = useContext(AppContext);

  useEffect(() => {
    setRefetchCompletedCalls(refetchCompletedTab);
    setRefetchReviewedCalls(refetchReviewedTab);
    setRefetchScheduledCalls(refetchScheduledTab);
    setRefetchTranscript(refetchTranscript);
  }, [
    setRefetchCompletedCalls,
    setRefetchReviewedCalls,
    setRefetchScheduledCalls,
    refetchReviewedTab,
    refetchCompletedTab,
    refetchScheduledTab,
    setRefetchTranscript,
    refetchTranscript,
  ]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTabIndex(newValue);

    const handleNavigation = (transcripts: TranscriptType[], loading: boolean) => {
      if (loading) return;

      if (transcripts.length === 0) {
        navigate(paths.home.reverse({ transcriptId: 'not-found' }));
        setTranscriptId('not-found');
        return;
      }

      navigate(paths.home.reverse({ transcriptId: transcripts[0]?.uuid }));
      setTranscriptId(transcripts[0]?.uuid);
    };

    switch (newValue) {
      case 0:
        handleNavigation(allScheduledTranscripts.results, scheduledLoading);
        break;
      case 1:
        handleNavigation(allCompletedTranscripts.results, completedLoading);
        break;
      case 2:
        handleNavigation(allReviewedTranscripts.results, reviewedLoading);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    setRefetchReviewedCalls(refetchReviewedTab);
    setRefetchTranscript(refetchTranscript);
  }, [
    setRefetchReviewedCalls,
    refetchReviewedTab,
    setRefetchTranscript,
    refetchTranscript,
  ]);

  useEffect(() => {
    calculateWidth();
    const newAudio = new Audio(audioUrl);
    setAudio(newAudio);

    return () => {
      newAudio.pause();
      newAudio.src = '';
      setIsPlayingMain(false);
      setAudio(null);
    };
  }, [audioUrl]);

  const statusToExclude = ['Scheduled', 'Created', 'In Progress', 'Canceled'];

  // autoselect tab where transcript should be
  useEffect(() => {
    if (!transcriptLoading && !isEmpty(transcriptWithType)) {
      if (transcriptWithType.call?.review.status.key === 'reviewed') {
        setSelectedTabIndex(2);
      } else if (transcriptWithType.call?.review.status.key !== 'reviewed'
        && transcriptWithType.call.status.value !== 'Suspended'
          && !statusToExclude.includes(transcriptWithType.call.status.value)) {
        setSelectedTabIndex(1);
      } else {
        setSelectedTabIndex(0);
      }
    }
    // eslint-disable-next-line
  }, [transcriptWithType, transcriptLoading]);

  // get transcript if transcript uuid changed or select first if there is no transcript in URL
  useEffect(() => {
    if ((transcriptId && !transcript) || (transcript && transcriptId && transcript.uuid !== transcriptId)) {
      getTranscript({
        variables: {
          uuid: transcriptId,
        },
      });
    }
    if (!transcriptUuid) {
      if (allReviewedTranscripts.results.length > 0) {
        navigate(allReviewedTranscripts.results[0].uuid);
        setTranscriptId(allReviewedTranscripts.results[0].uuid);
      }
    }
  // eslint-disable-next-line
  }, [getTranscript, transcriptId, transcriptUuid, allReviewedTranscripts.results.length]);

  const showAddCall = !me.isAcaReviewer;

  return (
    <ResearchAppHeaderLayout>
      {isMobile ? (
        <>
          <DrawerMobile anchor="left" open={openDrawerMobile}>
            {/* <DrawerHeader>
              <Typography variant="h4" color="colors.gray09" sx={{ fontSize: '24px', lineHeight: '32px' }}>
                Content
              </Typography>
            </DrawerHeader> */}
            <Box sx={{ borderBottom: '1px solid', borderBottomColor: 'colors.gray03' }}>
              <ContentTabs value={tabIndex} onChange={handleTabChange} variant="fullWidth" aria-label="sidebar tabs">
                <Tab label={`Scheduled (${allScheduledTranscripts.totalCount || 0})`} {...contentA11yProps(0)} />
                <Tab label={`Pending (${allCompletedTranscripts.totalCount || 0})`} {...contentA11yProps(1)} />
                <Tab label={`Reviewed (${allReviewedTranscripts.totalCount || 0})`} {...contentA11yProps(2)} />
              </ContentTabs>
            </Box>
            <ScrollContainer id="scrollableScheduledTab">
              <ContentTabPanel value={tabIndex} index={0}>
                <Box display="block">
                  <Box display="flex" flexDirection="column" flexGrow="1" pt="12px">
                    <InfiniteScroll
                      // @ts-ignore
                      pageStart={0}
                      dataLength={allScheduledTranscripts.results.length}
                      next={loadMoreScheduled}
                      hasMore={hasMoreScheduled}
                      loader={<p>Loading...</p>}
                      scrollableTarget="scrollableScheduledTab"
                    >
                      <ScheduledCallList
                        transcripts={allScheduledTranscripts.results}
                        onSelect={setTranscriptId}
                        selectedTranscriptId={transcriptId}
                        isResearchAnalyst
                        openDrawerMobile={openDrawerMobile}
                        setOpenDrawerMobile={setOpenDrawerMobile}
                      />
                    </InfiniteScroll>
                    {scheduledLoading && <CallListSkeleton />}
                  </Box>
                </Box>
              </ContentTabPanel>
            </ScrollContainer>
            <ScrollContainer id="scrollableCompletedTab">
              <ContentTabPanel value={tabIndex} index={1}>
                <Box display="block">
                  <Box display="flex" flexDirection="column" flexGrow="1" pt="12px">
                    <InfiniteScroll
                      // @ts-ignore
                      pageStart={0}
                      dataLength={allCompletedTranscripts.results.length}
                      next={loadMoreCompleted}
                      hasMore={hasMoreCompleted}
                      loader={<p>Loading...</p>}
                      scrollableTarget="scrollableCompletedTab"
                    >
                      <CallList
                        transcripts={allCompletedTranscripts.results}
                        onSelect={setTranscriptId}
                        selectedTranscriptId={transcriptId}
                        isResearchAnalyst
                        openDrawerMobile={openDrawerMobile}
                        setOpenDrawerMobile={setOpenDrawerMobile}
                      />
                    </InfiniteScroll>
                    {completedLoading && <CallListSkeleton />}
                  </Box>
                </Box>
              </ContentTabPanel>
            </ScrollContainer>
            <ScrollContainer id="scrollableReviewedTab">
              <ContentTabPanel value={tabIndex} index={2}>
                <Box display="block">
                  <Box display="flex" flexDirection="column" flexGrow="1" pt="12px">
                    <InfiniteScroll
                      // @ts-ignore
                      pageStart={0}
                      dataLength={allReviewedTranscripts.results.length}
                      next={loadMoreReviewed}
                      hasMore={hasMoreReviewed}
                      loader={<p>Loading...</p>}
                      scrollableTarget="scrollableReviewedTab"
                    >
                      <CallList
                        transcripts={allReviewedTranscripts.results}
                        onSelect={setTranscriptId}
                        selectedTranscriptId={transcriptId}
                        isResearchAnalyst
                        openDrawerMobile={openDrawerMobile}
                        setOpenDrawerMobile={setOpenDrawerMobile}
                      />
                    </InfiniteScroll>
                    {reviewedLoading && <CallListSkeleton />}
                  </Box>
                </Box>
              </ContentTabPanel>
            </ScrollContainer>
            {(!scheduledLoading && !allScheduledTranscripts.totalCount) && (
              <TabEmptyState isVisible={tabIndex === 0} title="No Scheduled Content" />
            )}
            {(!completedLoading && !allCompletedTranscripts.results.length) && (
              <TabEmptyState isVisible={tabIndex === 1} title="No Completed Content" />
            )}
            {(!reviewedLoading && !allReviewedTranscripts.results.length) && (
              <TabEmptyState isVisible={tabIndex === 2} title="No Reviewed Content" />
            )}
          </DrawerMobile>
          <Box sx={{ width: '100%' }}>
            {isNotFound ? (
              <CallNotFound />
            ) : (
              <Box display="flex" flexDirection="column" pt="72px">
                <HeaderMobile>
                  <DrawerButton aria-label="open drawer" onClick={() => setOpenDrawerMobile(!openDrawerMobile)}>
                    <AltArrowLeftIcon />
                    <Typography variant="body1" color="colors.gray05" sx={{ marginLeft: '8px' }}>
                      Back
                    </Typography>
                  </DrawerButton>
                  {!!transcriptWithType
                    && !!transcriptWithType.call?.transcript
                    && transcriptWithType.call?.transcript?.status?.value === 'Completed'
                    && (!settings.requireComplianceReview || transcript.call.review.status.value === 'Reviewed')
                    && (
                      <Download transcript={transcriptWithType} />
                    )}
                </HeaderMobile>
                {!!transcriptId && !transcriptLoading && (
                  <Box display="flex" alignItems="center" justifyContent="space-between" p="16px 20px">
                    <Box display="flex" flexDirection="column">
                      <Typography variant="h6" color="colors.gray10" sx={{ marginBottom: '5px' }}>
                        {!!transcriptWithType && transcriptWithType.call?.name}
                      </Typography>
                      <Typography variant="body1" color="colors.gray06" sx={{ fontSize: '14px', lineHeight: '22px' }}>
                        {transcriptWithType?.call?.type === 'meeting' ? 'Meeting date:' : 'Call date:'}
                        &nbsp;
                        {formatDateTimeToUserTimezone(transcriptWithType?.call?.datetime, me.timezone)}
                      </Typography>
                    </Box>
                    <Box height={40} width={40}>
                      <Box display="none" id="MobileArrowMoveUp">
                        <MoveToTopButton />
                      </Box>
                    </Box>
                  </Box>
                )}
                <Box display="flex" height="calc(100vh - 168px)">
                  <Box maxWidth="100%" id="CallDetailsColumn">
                    {transcriptWithType?.call ? (
                      <ResearchCallDetails
                        transcript={transcriptWithType}
                        selectedHighlight={selectedHighlight}
                        setSelectedHighlight={setSelectedHighlight}
                        isScheduledTab={tabIndex === 0}
                        accountName={transcript.call.account.name}
                        accountAvatarLogo={transcript.call.account.settings.avatarLogo}
                        audio={audio}
                        isPlayingMain={isPlayingMain}
                        setIsPlayingMain={setIsPlayingMain}
                        isPlayingParagraph={isPlayingParagraph}
                        setIsPlayingParagraph={setIsPlayingParagraph}
                        onSelect={setTranscriptId}
                      />
                    ) : (
                      <CallDetailsSkeleton />
                    )}
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </>
      ) : (
        <>
          <DrawerContainer variant="persistent" anchor="left" open={open}>
            <DrawerHeader
              isNotFound={isNotFound}
              toggleDrawer={toggleDrawer}
              onSearch={handleOnSearch}
              onBack={handleOnBack}
              isSearching={scheduledLoading || completedLoading || reviewedLoading}
            />
            <FiltersSection {...filterLogic} tabIndex={tabIndex} sectionsTohide={['complianceReviewerUuids', 'flags', 'display-in-research-portal']} />
            <Box sx={{ borderBottom: '1px solid', borderBottomColor: 'colors.gray03' }}>
              <ContentTabs value={tabIndex} onChange={handleTabChange} variant="fullWidth" aria-label="sidebar tabs">
                <Tab label={`Scheduled (${allScheduledTranscripts.totalCount || 0})`} {...contentA11yProps(0)} />
                <Tab label={`Pending Review (${allCompletedTranscripts.totalCount || 0})`} {...contentA11yProps(1)} />
                <Tab label={`Reviewed (${allReviewedTranscripts.totalCount || 0})`} {...contentA11yProps(2)} />
              </ContentTabs>
            </Box>
            <ScrollContainer id="scrollableScheduledTab">
              <ContentTabPanel value={tabIndex} index={0}>
                <Box display="block">
                  <Box display="flex" flexDirection="column" flexGrow="1" pt="12px">
                    <InfiniteScroll
                      // @ts-ignore
                      pageStart={0}
                      dataLength={allScheduledTranscripts.results.length}
                      next={loadMoreScheduled}
                      hasMore={hasMoreScheduled}
                      loader={<p>Loading...</p>}
                      scrollableTarget="scrollableScheduledTab"
                    >
                      <ScheduledCallList
                        transcripts={allScheduledTranscripts.results}
                        onSelect={setTranscriptId}
                        selectedTranscriptId={transcriptId}
                        isResearchAnalyst
                      />
                    </InfiniteScroll>
                    {scheduledLoading && <CallListSkeleton />}
                  </Box>
                </Box>
              </ContentTabPanel>
            </ScrollContainer>
            <ScrollContainer id="scrollableCompletedTab">
              <ContentTabPanel value={tabIndex} index={1}>
                <Box display="block">
                  <Box display="flex" flexDirection="column" flexGrow="1" pt="12px">
                    <InfiniteScroll
                      // @ts-ignore
                      pageStart={0}
                      dataLength={allCompletedTranscripts.results.length}
                      next={loadMoreCompleted}
                      hasMore={hasMoreCompleted}
                      loader={<p>Loading...</p>}
                      scrollableTarget="scrollableCompletedTab"
                    >
                      <CallList
                        transcripts={allCompletedTranscripts.results}
                        onSelect={setTranscriptId}
                        selectedTranscriptId={transcriptId}
                        isResearchAnalyst
                      />
                    </InfiniteScroll>
                    {completedLoading && <CallListSkeleton />}
                  </Box>
                </Box>
              </ContentTabPanel>
            </ScrollContainer>
            <ScrollContainer id="scrollableReviewedTab">
              <ContentTabPanel value={tabIndex} index={2}>
                <Box display="block">
                  <Box display="flex" flexDirection="column" flexGrow="1" pt="12px">
                    <InfiniteScroll
                      // @ts-ignore
                      pageStart={0}
                      dataLength={allReviewedTranscripts.results.length}
                      next={loadMoreReviewed}
                      hasMore={hasMoreReviewed}
                      loader={<p>Loading...</p>}
                      scrollableTarget="scrollableReviewedTab"
                    >
                      <CallList
                        transcripts={allReviewedTranscripts.results}
                        onSelect={setTranscriptId}
                        selectedTranscriptId={transcriptId}
                        isResearchAnalyst
                      />
                    </InfiniteScroll>
                    {reviewedLoading && <CallListSkeleton />}
                  </Box>
                </Box>
              </ContentTabPanel>
            </ScrollContainer>
            {(!scheduledLoading && !allScheduledTranscripts.totalCount) && (
              <TabEmptyState isVisible={tabIndex === 0} title="No Scheduled Content" />
            )}
            {(!completedLoading && !allCompletedTranscripts.results.length) && (
              <TabEmptyState isVisible={tabIndex === 1} title="No Completed Content" />
            )}
            {(!reviewedLoading && !allReviewedTranscripts.results.length) && (
              <TabEmptyState isVisible={tabIndex === 2} title="No Reviewed Content" />
            )}
          </DrawerContainer>
          <Main open={open}>
            {isNotFound ? (
              <CallNotFound />
            ) : (
              <Box display="flex" flexDirection="column">
                <HeaderContainer>
                  <Box display="flex" sx={{ ...(open && { display: 'none' }) }}>
                    <DrawerButton aria-label="open drawer" onClick={toggleDrawer}>
                      <DrawerIcon />
                    </DrawerButton>
                    <VerticalDivider orientation="vertical" flexItem />
                  </Box>
                  {!!transcriptId && !transcriptLoading && (
                    <Box display="flex" alignItems="center" justifyContent="space-between" flexGrow={1}>
                      <Box>
                        <Typography variant="h5" color="colors.gray10" sx={{ marginBottom: '5px' }}>
                          {!!transcriptWithType && transcriptWithType.call?.name}
                        </Typography>
                        <Typography variant="body1" color="colors.gray06" sx={{ fontSize: '14px', lineHeight: '22px' }}>
                          {transcriptWithType?.call?.type === 'meeting' ? 'Meeting date:' : 'Call date:'}
                          &nbsp;
                          {formatDateTimeToUserTimezone(transcriptWithType?.call?.datetime, me.timezone)}
                        </Typography>
                      </Box>
                      <Box>
                        {!!transcriptWithType
                          && !!transcriptWithType.call?.transcript
                          && transcriptWithType.call?.transcript?.status?.value === 'Completed'
                          && (!settings.requireComplianceReview || transcript.call.review.status.value === 'Reviewed')
                          && (
                            <Download transcript={transcriptWithType} />
                          )}
                      </Box>
                    </Box>
                  )}
                  {!transcriptId && <HeaderEmptyState />}
                  {transcriptLoading && <HeaderSkeleton />}
                </HeaderContainer>
                <Box display="flex" height="calc(100vh - 168px)">
                  <Box width="100%" id="CallDetailsColumn">
                    {transcriptWithType?.call ? (
                      <ResearchCallDetails
                        transcript={transcriptWithType}
                        selectedHighlight={selectedHighlight}
                        setSelectedHighlight={setSelectedHighlight}
                        isScheduledTab={tabIndex === 0}
                        accountName={transcript.call.account.name}
                        accountAvatarLogo={transcript.call.account.settings.avatarLogo}
                        audio={audio}
                        isPlayingMain={isPlayingMain}
                        setIsPlayingMain={setIsPlayingMain}
                        isPlayingParagraph={isPlayingParagraph}
                        setIsPlayingParagraph={setIsPlayingParagraph}
                        onSelect={setTranscriptId}
                      />
                    ) : (
                      <CallDetailsSkeleton />
                    )}
                  </Box>
                </Box>
              </Box>
            )}
          </Main>
        </>
      )}
    </ResearchAppHeaderLayout>
  );
};

export default ResearchAppCalls;
