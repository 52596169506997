/* eslint-disable import/order */
import React, { useState, useEffect } from 'react';

// * MUI
import {
  Box,
  Dialog,
  Divider,
  Typography,
  FormGroup,
  Select,
  MenuItem,
  FormControlLabel,
  InputAdornment,
} from '@mui/material';

// * Components
import {
  CardHeader,
  UpdateBtn,
  EditBtn,
  CancelButton,
  SubmitButton,
  CancelBtn,
  CustomCheckbox,
  SelectControl,
  DigitsField,
  EmailBox,
  EmailItemBox,
} from './styledComponents';
import ResearchUsersDialog from './ResearchUsersDialog';
import CustomComplianceChecks from './CustomComplianceChecks';
import Disclosure from './Disclosure';

// * Icons
import { ReactComponent as ArrowLeftIcon } from '../../assets/icons/arrowLeftIcon.svg';
import { ReactComponent as InfoCircleIcon } from '../../assets/icons/infoCircleIcon.svg';
import { ReactComponent as AltNotesIcon } from '../../assets/icons/altNotesIcon.svg';
import { ReactComponent as TimerIcon } from '../../assets/icons/timerIcon.svg';
import { ReactComponent as CallReceivedIcon } from '../../assets/icons/callReceivedIcon.svg';
import { ReactComponent as InformationIcon } from '../../assets/icons/informationIcon.svg';
import { ReactComponent as AltLetterIcon } from '../../assets/icons/altLetterIcon.svg';
import { ReactComponent as ConfidenceLevelIcon } from '../../assets/icons/confidenceLevel.svg';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';

// * Hooks & Utils
import { useMe } from '../AppContextProvider';
import { formatDateTimeToUserTimezone } from '../../shared/utils/dateHelpers';
import palette from '../../theme/palette';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import isEmpty from 'lodash/isEmpty';

// * Queries & Mutations
import {
  ACCOUNT_SETTINGS_MUTATION,
  ACCOUNT_USERS,
} from '../../queries/auth/account';

// * Interfaces
import type {
  AccountSettingsType,
  AccountSettingsSubmitType,
  AccountUsersType,
  ComplianceCheckItem,
} from '../../shared/types/accountSettings';

const getUniqueTypes = (
  complianceChecks: ComplianceCheckItem[],
): ComplianceCheckItem['type'][] => Array.from(new Set(complianceChecks.map(item => item.type)));

const initializeCustomComplianceChecks = (
  types: ComplianceCheckItem['type'][],
): AccountSettingsSubmitType['customComplianceChecks'] => types.reduce((acc, type) => {
  acc[type] = {};
  return acc;
}, {} as AccountSettingsSubmitType['customComplianceChecks']);

type OrganizationGeneralSettingsProps = {
  settings: AccountSettingsType
  refetchQueries: any[]
  editMode: boolean
  setEditMode: any
}

const schema = yup.object().shape({
  sampleSize: yup
    .string()
    .test(
      'no-leading-plus',
      'Value cannot start with +',
      // @ts-ignore
      value => !value.startsWith('+'),
    )
    .test('min-max', 'Value needs to be from 1-100', value => {
      // @ts-ignore
      const numberValue = parseInt(value, 10);
      return numberValue >= 1 && numberValue <= 100;
    })
    .matches(
      /^[1-9]\d*$/,
      'Only positive numbers without leading +, -, or 0 are allowed',
    )
    .required('Value needs to be from 1-100'),
  allowSendTranscriptUponReview: yup.boolean(),
  researchBoxAttachmentPreference: yup
    .string()
    .oneOf(['Summary only', 'Transcript and Summary']),
});

const OrganizationGeneralSettingsForm = ({
  settings,
  refetchQueries,
  editMode,
  setEditMode,
}: OrganizationGeneralSettingsProps) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [researchUsers, setResearchUsers] = useState(
    settings.researchUsers || [],
  );
  const [accountUsers, setAccountUsers] = useState<AccountUsersType[]>([]);

  const [fetchAccountUsers] = useLazyQuery<{
    accountUsers: AccountUsersType[]
  }>(ACCOUNT_USERS, {
    fetchPolicy: 'network-only',
    variables: { uuid: settings.uuid },
    onCompleted: data => setAccountUsers(data.accountUsers),
  });
  const [accountSettingsMutation, { loading }] = useMutation(
    ACCOUNT_SETTINGS_MUTATION,
  );

  useEffect(() => {
    fetchAccountUsers();
  }, [fetchAccountUsers]);

  const { me } = useMe();

  const uniqueTypes = getUniqueTypes(settings.complianceChecks);

  const defaultValues: AccountSettingsSubmitType = {
    mnpi: settings.mnpi,
    confidenceLevel: settings.confidenceLevel,
    displayInResearchPortal: settings.displayInResearchPortal,
    shadowTrading: settings.shadowTrading,
    reluctance: settings.reluctance,
    meeting: settings.meeting,
    contactInfo: settings.contactInfo,
    tippee: settings.tippee,
    preamble: settings.preamble,
    disclosurePrompt: settings.disclosurePrompt,
    deleteTranscript: settings.deleteTranscript,
    transcriptRetentionPeriod: settings.transcriptRetentionPeriod.key,
    sampleSize: settings.sampleSize,
    requireComplianceReview: settings.requireComplianceReview,
    transcribeAllCalls: settings.transcribeAllCalls,
    reviewTranscriptsWithNoHighlights:
      settings.reviewTranscriptsWithNoHighlights,
    includeAllExpertsFromPublicCompaniesInSample:
      settings.includeAllExpertsFromPublicCompaniesInSample,
    lastEmployed: settings.lastEmployed.key,
    researchUsers: researchUsers.map(user => user.id),
    allowSendTranscriptUponReview: settings.allowSendTranscriptUponReview,
    researchBoxAttachmentPreference: settings.researchBoxAttachmentPreference,
    customComplianceChecks: settings.complianceChecks.reduce(
      (acc, item) => {
        acc[item.type][item.name] = item.isActive;
        return acc;
      },
      initializeCustomComplianceChecks(uniqueTypes),
    ),
  };

  const {
    control,
    reset,
    watch,
    handleSubmit,
    formState: { errors, dirtyFields },
  } = useForm<AccountSettingsSubmitType>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    defaultValues,
    // @ts-ignore
    resolver: yupResolver(schema),
  });

  const ignoredField = 'researchBoxEmails';
  const isDirtyWithoutIgnoredField = Object.keys(dirtyFields)
    // @ts-ignore
    .some(field => field !== ignoredField && dirtyFields[field]);

  const disabledUpdateBtn = loading
    || !isEmpty(errors)
    || (!isDirtyWithoutIgnoredField
      && JSON.stringify(researchUsers) === JSON.stringify(settings.researchUsers));

  const preamble = watch('preamble');
  const watchDeleteTranscript = watch('deleteTranscript');
  const watchIncludeAllExpertsFromPublicCompaniesInSample = watch(
    'includeAllExpertsFromPublicCompaniesInSample',
  );

  const handleEdit = () => {
    reset(defaultValues);
    setEditMode(true);
  };

  const handleCancel = () => {
    reset(defaultValues);
    setEditMode(false);
  };

  const handleOpenDialog = () => {
    setOpenDialog(!openDialog);
  };

  const onSubmit = (data: AccountSettingsSubmitType) => {
    accountSettingsMutation({
      variables: {
        input: {
          uuid: settings.uuid,
          ...data,
          researchUsers: researchUsers.map(user => user.id),
          sampleSize: parseInt(data.sampleSize, 10),
          customComplianceChecks: JSON.stringify(data.customComplianceChecks),
        },
      },
      awaitRefetchQueries: true,
      refetchQueries,
    }).then(({ data: { accountSettings } }) => {
      fetchAccountUsers();
      if (accountSettings.errors.length === 0) {
        handleOpenDialog();
        setEditMode(false);
      }
    });
  };

  const updateResearchUsers = (userIds: string[]) => {
    setResearchUsers(prev => {
      const newUsers = accountUsers.filter(user => userIds.includes(user.id));
      return [...prev, ...newUsers];
    });
  };

  const removeResearchUser = (userIdToRemove: string) => {
    setResearchUsers(researchUsers.filter(item => item.id !== userIdToRemove));
  };

  return (
    <form>
      <CardHeader>
        {editMode && (
          <CancelBtn onClick={handleCancel}>
            <ArrowLeftIcon />
            <Typography
              variant="h6"
              color="colors.gray07"
              sx={{ marginLeft: '12px' }}
            >
              Cancel
            </Typography>
          </CancelBtn>
        )}
        <Typography variant="h4" sx={{ fontSize: '24px', lineHeight: '32px' }}>
          {editMode ? 'Edit Organization Settings' : 'Organization Settings'}
        </Typography>
        {editMode ? (
          <UpdateBtn
            variant="contained"
            startIcon={<CheckCircleIcon />}
            disabled={disabledUpdateBtn}
            onClick={handleOpenDialog}
          >
            Update
          </UpdateBtn>
        ) : (
          <Box display="flex" alignItems="center">
            <Typography
              variant="subtitle2"
              color="colors.gray06"
              mr="12px"
              sx={{ fontWeight: 500 }}
            >
              {/* Last updated by <PrimaryTypo> Jessica Born </PrimaryTypo> */}
              Last updated at
              {' '}
              {formatDateTimeToUserTimezone(settings.updatedAt, me.timezone)}
            </Typography>
            <EditBtn
              variant="outlined"
              startIcon={<BorderColorOutlinedIcon />}
              onClick={handleEdit}
            >
              Edit
            </EditBtn>
          </Box>
        )}
        <Dialog open={openDialog} onClose={handleOpenDialog}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: '55px 29px',
            }}
          >
            <Box display="flex" mb="8px">
              <InfoCircleIcon />
            </Box>
            <Typography
              variant="h3"
              color="colors.gray10"
              textAlign="center"
              mb="25px"
            >
              Update Organization Settings
            </Typography>
            <Typography
              variant="body1"
              color="colors.gray10"
              textAlign="center"
              sx={{ fontWeight: 400 }}
            >
              Are you sure you want to update your Organization Settings?
            </Typography>
            <Box display="flex" justifyContent="center" mt="36px">
              <CancelButton variant="outlined" onClick={handleOpenDialog}>
                Cancel
              </CancelButton>
              <SubmitButton
                variant="contained"
                type="submit"
                disabled={loading}
                onClick={handleSubmit(onSubmit)}
              >
                Yes, Update
              </SubmitButton>
            </Box>
          </Box>
        </Dialog>
      </CardHeader>
      <Divider
        flexItem
        sx={{ margin: '0 0 32px', borderColor: 'colors.gray03' }}
      />
      <Box display="flex" flexDirection="column" padding="0 48px 48px">
        <Box display="flex">
          <Box
            width="50%"
            pr="50px"
            sx={{ borderRight: '1px solid', borderRightColor: 'colors.gray03' }}
          >
            <Typography variant="h6" mb="16px">
              Predefined Policies
            </Typography>
            <FormGroup sx={{ flexDirection: 'column' }}>
              <FormControlLabel
                disabled={!editMode}
                sx={{
                  alignItems: 'flex-start',
                  marginLeft: 0,
                  marginBottom: '16px',
                }}
                control={(
                  <Controller
                    name="mnpi"
                    control={control}
                    render={({ field: { onChange, value, ref } }) => (
                      <CustomCheckbox
                        disabled={!editMode}
                        onChange={onChange}
                        checked={value}
                        inputRef={ref}
                      />
                    )}
                  />
                )}
                label={(
                  <Box display="flex" flexDirection="column">
                    <Typography variant="body1" sx={{ fontWeight: 400 }}>
                      MNPI
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      color="colors.gray06"
                      sx={{ fontWeight: 500 }}
                    >
                      MNPI for public companies
                    </Typography>
                  </Box>
                )}
              />
              <FormControlLabel
                disabled={!editMode}
                sx={{
                  alignItems: 'flex-start',
                  marginLeft: 0,
                  marginBottom: '16px',
                }}
                control={(
                  <Controller
                    name="shadowTrading"
                    control={control}
                    render={({ field: { onChange, value, ref } }) => (
                      <CustomCheckbox
                        disabled={!editMode}
                        onChange={onChange}
                        checked={value}
                        inputRef={ref}
                      />
                    )}
                  />
                )}
                label={(
                  <Box display="flex" flexDirection="column">
                    <Typography variant="body1" sx={{ fontWeight: 400 }}>
                      Shadow Trading
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      color="colors.gray06"
                      sx={{ fontWeight: 500 }}
                    >
                      MNPI for private companies
                    </Typography>
                  </Box>
                )}
              />
              <FormControlLabel
                disabled={!editMode}
                sx={{
                  alignItems: 'flex-start',
                  marginLeft: 0,
                  marginBottom: '16px',
                }}
                control={(
                  <Controller
                    name="reluctance"
                    control={control}
                    render={({ field: { onChange, value, ref } }) => (
                      <CustomCheckbox
                        disabled={!editMode}
                        onChange={onChange}
                        checked={value}
                        inputRef={ref}
                      />
                    )}
                  />
                )}
                label={(
                  <Box display="flex" flexDirection="column">
                    <Typography variant="body1" sx={{ fontWeight: 400 }}>
                      Reluctance
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      color="colors.gray06"
                      sx={{ fontWeight: 500 }}
                    >
                      Reluctance to share information
                    </Typography>
                  </Box>
                )}
              />
              <FormControlLabel
                disabled={!editMode}
                sx={{
                  alignItems: 'flex-start',
                  marginLeft: 0,
                  marginBottom: '16px',
                }}
                control={(
                  <Controller
                    name="meeting"
                    control={control}
                    render={({ field: { onChange, value, ref } }) => (
                      <CustomCheckbox
                        disabled={!editMode}
                        onChange={onChange}
                        checked={value}
                        inputRef={ref}
                      />
                    )}
                  />
                )}
                label={(
                  <Box display="flex" flexDirection="column">
                    <Typography variant="body1" sx={{ fontWeight: 400 }}>
                      Meeting
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      color="colors.gray06"
                      sx={{ fontWeight: 500 }}
                    >
                      Attempts to set up a meeting
                    </Typography>
                  </Box>
                )}
              />
              <FormControlLabel
                disabled={!editMode}
                sx={{
                  alignItems: 'flex-start',
                  marginLeft: 0,
                  marginBottom: '16px',
                }}
                control={(
                  <Controller
                    name="contactInfo"
                    control={control}
                    render={({ field: { onChange, value, ref } }) => (
                      <CustomCheckbox
                        disabled={!editMode}
                        onChange={onChange}
                        checked={value}
                        inputRef={ref}
                      />
                    )}
                  />
                )}
                label={(
                  <Box display="flex" flexDirection="column">
                    <Typography variant="body1" sx={{ fontWeight: 400 }}>
                      Contact Info
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      color="colors.gray06"
                      sx={{ fontWeight: 500 }}
                    >
                      Exchange of contact information
                    </Typography>
                  </Box>
                )}
              />
              <FormControlLabel
                disabled={!editMode}
                sx={{
                  alignItems: 'flex-start',
                  marginLeft: 0,
                  marginBottom: '16px',
                }}
                control={(
                  <Controller
                    name="tippee"
                    control={control}
                    render={({ field: { onChange, value, ref } }) => (
                      <CustomCheckbox
                        disabled={!editMode}
                        onChange={onChange}
                        checked={value}
                        inputRef={ref}
                      />
                    )}
                  />
                )}
                label={(
                  <Box display="flex" flexDirection="column">
                    <Typography variant="body1" sx={{ fontWeight: 400 }}>
                      Tippee
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      color="colors.gray06"
                      sx={{ fontWeight: 500 }}
                    >
                      Information received from a friend
                    </Typography>
                  </Box>
                )}
              />
              <FormControlLabel
                disabled={!editMode}
                sx={{ alignItems: 'flex-start', marginLeft: 0 }}
                control={(
                  <Controller
                    name="preamble"
                    control={control}
                    render={({ field: { onChange, value, ref } }) => (
                      <CustomCheckbox
                        disabled={!editMode}
                        onChange={onChange}
                        checked={value}
                        inputRef={ref}
                      />
                    )}
                  />
                )}
                label={(
                  <Box display="flex" flexDirection="column">
                    <Typography variant="body1" sx={{ fontWeight: 400 }}>
                      Preamble
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      color="colors.gray06"
                      sx={{ fontWeight: 500 }}
                    >
                      Missing compliance preamble
                    </Typography>
                  </Box>
                )}
              />
            </FormGroup>
          </Box>
          <CustomComplianceChecks
            complianceChecks={settings.complianceChecks}
            editMode={editMode}
            formControl={control}
          />
        </Box>
        <Divider
          flexItem
          sx={{ margin: '24px 0', borderColor: 'colors.gray03' }}
        />
        <Box display="flex" alignItems="center" mb="12px">
          <AltNotesIcon />
          <Typography variant="subtitle1" color="colors.gray10" ml="12px">
            Compliance Preamble
          </Typography>
        </Box>
        <Disclosure
          formControl={control}
          settings={settings}
          editMode={editMode}
          preambleWatch={preamble}
          errors={errors}
        />
        <Divider
          flexItem
          sx={{ margin: '24px 0', borderColor: 'colors.gray03' }}
        />
        <Typography variant="h6" mb="24px">
          Display in Research Portal
        </Typography>
        <FormGroup sx={{ flexDirection: 'column' }}>
          <FormControlLabel
            disabled={!editMode}
            sx={{
              alignItems: 'flex-start',
              marginLeft: 0,
              marginBottom: '24px',
            }}
            control={(
              <Controller
                name="displayInResearchPortal"
                control={control}
                render={({ field: { onChange, value, ref } }) => (
                  <CustomCheckbox
                    disabled={!editMode}
                    onChange={onChange}
                    checked={value}
                    inputRef={ref}
                  />
                )}
              />
            )}
            label={(
              <Box display="flex" flexDirection="column">
                <Typography variant="body1">Default Value</Typography>
                <Typography
                  variant="subtitle1"
                  color="colors.gray06"
                  sx={{ fontWeight: 400 }}
                >
                  Default setting for displaying items in the Research Portal.
                  Checked indicates it will display by default.
                </Typography>
              </Box>
            )}
          />
        </FormGroup>
        <Divider
          flexItem
          sx={{ margin: '24px 0', borderColor: 'colors.gray03' }}
        />
        <Typography variant="h6" mb="24px">
          Transcript Retention
        </Typography>
        <FormGroup sx={{ flexDirection: 'column' }}>
          <FormControlLabel
            disabled={!editMode}
            sx={{
              alignItems: 'flex-start',
              marginLeft: 0,
              marginBottom: '24px',
            }}
            control={(
              <Controller
                name="deleteTranscript"
                control={control}
                render={({ field: { onChange, value, ref } }) => (
                  <CustomCheckbox
                    disabled={!editMode}
                    onChange={onChange}
                    checked={value}
                    inputRef={ref}
                  />
                )}
              />
            )}
            label={(
              <Box display="flex" flexDirection="column">
                <Typography variant="body1">Delete Transcripts</Typography>
                <Typography
                  variant="subtitle1"
                  color="colors.gray06"
                  sx={{ fontWeight: 400 }}
                >
                  Once deleted in the system, the transcript exists an addition
                  30 days in the system backups.
                </Typography>
              </Box>
            )}
          />
        </FormGroup>
        <Typography variant="body1" mb="4px" ml="50px">
          Transcript Retention Period
        </Typography>
        <Controller
          name="transcriptRetentionPeriod"
          control={control}
          render={({
            field: {
              onChange, value, name, ref,
            },
          }) => (
            <SelectControl>
              <Select
                inputRef={ref}
                name={name}
                value={value}
                onChange={onChange}
                disabled={!editMode || !watchDeleteTranscript}
                IconComponent={ExpandMoreIcon}
                sx={{ height: 58, width: 430, marginLeft: '50px' }}
                renderValue={selected => (
                  <Box display="flex" alignItems="center">
                    <TimerIcon />
                    <Typography variant="body1" ml="12px">
                      {
                        settings.transcriptRetentionPeriod.options.find(
                          item => item.key === selected,
                        )?.value
                      }
                    </Typography>
                  </Box>
                )}
              >
                {settings.transcriptRetentionPeriod.options.map(item => (
                  <MenuItem key={item.key} value={item.key}>
                    {item.value}
                  </MenuItem>
                ))}
              </Select>
            </SelectControl>
          )}
        />
        <Divider
          flexItem
          sx={{ margin: '24px 0', borderColor: 'colors.gray03' }}
        />
        <Typography variant="h6" mb="24px">
          Sample Size
        </Typography>
        <Typography
          variant="subtitle1"
          color="colors.gray06"
          sx={{ fontWeight: 400, marginBottom: '12px' }}
        >
          Enter the percentage of calls the system should review. Calls not
          included in the random sample will not be recorded. Enter 100% to
          review all calls.
        </Typography>
        <Controller
          name="sampleSize"
          control={control}
          rules={{
            required: 'Value needs to be from 1-100',
          }}
          render={({
            field: {
              onChange, value, name, ref,
            },
            fieldState: { error },
          }) => (
            <Box display="flex" alignItems="center">
              <DigitsField
                inputRef={ref}
                name={name}
                value={value}
                error={!!error}
                onChange={onChange}
                disabled={!editMode}
                variant="outlined"
                placeholder="1-100"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <CallReceivedIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <Typography
                        sx={{
                          '-webkit-text-fill-color': editMode
                            ? ''
                            : 'rgba(0, 0, 0, 0.38)',
                        }}
                      >
                        %
                      </Typography>
                    </InputAdornment>
                  ),
                }}
              />
              {error && (
                <Box display="flex" alignItems="center" ml="12px">
                  <InformationIcon />
                  <Typography
                    variant="body2"
                    color="colors.error04"
                    ml="4px"
                    sx={{ fontWeight: 400 }}
                  >
                    {error?.message}
                  </Typography>
                </Box>
              )}
            </Box>
          )}
        />
        <FormGroup sx={{ flexDirection: 'column' }}>
          <FormControlLabel
            disabled={!editMode}
            sx={{
              alignItems: 'flex-start',
              marginLeft: 0,
              marginTop: '24px',
              marginBottom: '24px',
            }}
            control={(
              <Controller
                name="transcribeAllCalls"
                control={control}
                render={({ field: { onChange, value, ref } }) => (
                  <CustomCheckbox
                    disabled={!editMode}
                    onChange={onChange}
                    checked={value}
                    inputRef={ref}
                  />
                )}
              />
            )}
            label={(
              <Box display="flex" flexDirection="column">
                <Typography variant="body1">Transcribe all calls</Typography>
                <Typography
                  variant="subtitle1"
                  color="colors.gray06"
                  sx={{ fontWeight: 400 }}
                >
                  Select this to transcribe all calls whether they were selected
                  in the sample or not. Calls not selected in the sample will be
                  sent to the Research Portal/Data Feed without compliance
                  review.
                </Typography>
              </Box>
            )}
          />
        </FormGroup>
        <FormGroup sx={{ flexDirection: 'column' }}>
          <FormControlLabel
            disabled={!editMode}
            sx={{
              alignItems: 'flex-start',
              marginLeft: 0,
              marginBottom: '24px',
            }}
            control={(
              <Controller
                name="requireComplianceReview"
                control={control}
                render={({ field: { onChange, value, ref } }) => (
                  <CustomCheckbox
                    disabled={!editMode}
                    onChange={onChange}
                    checked={value}
                    inputRef={ref}
                  />
                )}
              />
            )}
            label={(
              <Box display="flex" flexDirection="column">
                <Typography variant="body1">
                  Require compliance review prior to displaying calls in
                  Research Portal
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="colors.gray06"
                  sx={{ fontWeight: 400 }}
                >
                  If checked, calls will only be available in the Research
                  Portal once compliance review has been completed. If not
                  checked, calls will be available immediately in the Research
                  Portal, prior to compliance review.
                </Typography>
              </Box>
            )}
          />
        </FormGroup>
        <FormGroup sx={{ flexDirection: 'column' }}>
          <FormControlLabel
            disabled={!editMode}
            sx={{
              alignItems: 'flex-start',
              marginLeft: 0,
              marginBottom: '24px',
            }}
            control={(
              <Controller
                name="reviewTranscriptsWithNoHighlights"
                control={control}
                render={({ field: { onChange, value, ref } }) => (
                  <CustomCheckbox
                    disabled={!editMode}
                    onChange={onChange}
                    checked={value}
                    inputRef={ref}
                  />
                )}
              />
            )}
            label={(
              <Box display="flex" flexDirection="column">
                <Typography variant="body1">
                  Review transcripts with no items of interest
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="colors.gray06"
                  sx={{ fontWeight: 400 }}
                >
                  If there are no items of interest for a transcript, require
                  compliance review prior to moving to Reviewed tab.
                </Typography>
              </Box>
            )}
          />
        </FormGroup>
        <FormGroup sx={{ flexDirection: 'column' }}>
          <FormControlLabel
            disabled={!editMode}
            sx={{
              alignItems: 'flex-start',
              marginLeft: 0,
              marginBottom: '24px',
            }}
            control={(
              <Controller
                name="includeAllExpertsFromPublicCompaniesInSample"
                control={control}
                render={({ field: { onChange, value, ref } }) => (
                  <CustomCheckbox
                    disabled={!editMode}
                    onChange={onChange}
                    checked={value}
                    inputRef={ref}
                  />
                )}
              />
            )}
            label={(
              <Box display="flex" flexDirection="column">
                <Typography variant="body1">
                  Include all experts from public companies in sample
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="colors.gray06"
                  sx={{ fontWeight: 400 }}
                >
                  Select this option to include experts in your sample who were
                  last employed by a public company, as specified in the
                  &quot;Last Employed&quot; field.
                </Typography>
              </Box>
            )}
          />
        </FormGroup>
        <Typography variant="body1" mb="4px" ml="50px">
          Last Employed
        </Typography>
        <Controller
          name="lastEmployed"
          control={control}
          render={({
            field: {
              onChange, value, name, ref,
            },
          }) => (
            <SelectControl>
              <Select
                inputRef={ref}
                name={name}
                value={value}
                onChange={onChange}
                disabled={
                  !editMode
                  || !watchIncludeAllExpertsFromPublicCompaniesInSample
                }
                IconComponent={ExpandMoreIcon}
                sx={{ height: 58, width: 430, marginLeft: '50px' }}
                renderValue={selected => (
                  <Box display="flex" alignItems="center">
                    <TimerIcon />
                    <Typography variant="body1" ml="12px">
                      {
                        settings.lastEmployed.options.find(
                          item => item.key === selected,
                        )?.value
                      }
                    </Typography>
                  </Box>
                )}
              >
                {settings.lastEmployed.options.map(item => (
                  <MenuItem key={item.key} value={item.key}>
                    {item.value}
                  </MenuItem>
                ))}
              </Select>
            </SelectControl>
          )}
        />
        <Divider
          flexItem
          sx={{ margin: '24px 0', borderColor: 'colors.gray03' }}
        />
        <Typography variant="h6" mb="24px">
          Confidence Level
        </Typography>
        <Controller
          name="confidenceLevel"
          control={control}
          rules={{
            required: 'Please select a confidence level',
          }}
          render={({
            field: { onChange, value, ref },
            fieldState: { error },
          }) => (
            <Box display="flex" alignItems="center">
              <Select
                value={value}
                onChange={onChange}
                inputRef={ref}
                disabled={!editMode}
                startAdornment={(
                  <InputAdornment position="start">
                    <ConfidenceLevelIcon />
                  </InputAdornment>
                )}
              >
                <MenuItem value={80}>80%</MenuItem>
                <MenuItem value={85}>85%</MenuItem>
                <MenuItem value={90}>90%</MenuItem>
                <MenuItem value={95}>95%</MenuItem>
              </Select>
              {error && (
                <Box display="flex" alignItems="center" ml="12px">
                  <InformationIcon />
                  <Typography
                    variant="body2"
                    color="colors.error04"
                    ml="4px"
                    sx={{ fontWeight: 400 }}
                  >
                    {error?.message}
                  </Typography>
                </Box>
              )}
            </Box>
          )}
        />
        <Typography
          variant="subtitle1"
          color="colors.gray06"
          sx={{ fontWeight: 400, marginTop: '12px' }}
        >
          Enter the percentage of items of interest to include based on their
          confidence level. Only items of interest with confidence equal to or greater
          than the selected level will be included.
        </Typography>
        <Divider
          flexItem
          sx={{ margin: '24px 0', borderColor: 'colors.gray03' }}
        />
        <Typography variant="h6" mb="24px">
          Send Transcript
        </Typography>
        <Box display="flex" justifyContent="space-between">
          <FormGroup sx={{ flexDirection: 'column' }}>
            <FormControlLabel
              disabled={!editMode}
              sx={{
                alignItems: 'flex-start',
                marginLeft: 0,
                marginBottom: '24px',
              }}
              control={(
                <Controller
                  name="allowSendTranscriptUponReview"
                  control={control}
                  render={({ field: { onChange, value, ref } }) => (
                    <CustomCheckbox
                      disabled={!editMode}
                      onChange={onChange}
                      checked={value}
                      inputRef={ref}
                    />
                  )}
                />
              )}
              label={(
                <Box display="flex" flexDirection="column">
                  <Typography variant="body1">
                    Send to Research upon review
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    color="colors.gray06"
                    sx={{ fontWeight: 400 }}
                  >
                    Once review is completed, the transcript will be send out to
                    mentioned emails below.
                  </Typography>
                </Box>
              )}
            />
          </FormGroup>
          <Box>
            <Controller
              name="researchBoxAttachmentPreference"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Select
                  name="researchBoxAttachmentPreference"
                  value={value}
                  onChange={onChange}
                  disabled={!editMode}
                  IconComponent={ExpandMoreIcon}
                  sx={{ width: 250 }}
                >
                  <MenuItem value="Transcript and Summary">
                    Transcript and Summary
                  </MenuItem>
                  <MenuItem value="Summary only">Summary only</MenuItem>
                </Select>
              )}
            />
            {errors.researchBoxAttachmentPreference && (
              <Typography variant="body1" color="error">
                {errors.researchBoxAttachmentPreference.message}
              </Typography>
            )}
          </Box>
        </Box>
        <EmailBox>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            px="6px"
          >
            <Typography variant="body2" color="colors.gray07">
              Users to deliver transcript
            </Typography>
            <ResearchUsersDialog
              editMode={editMode}
              accountUsers={accountUsers}
              updateResearchUsers={updateResearchUsers}
              researchUsers={researchUsers}
            />
          </Box>
          <Divider
            flexItem
            sx={{ margin: '12px 6px', borderColor: 'colors.gray03' }}
          />
          {researchUsers?.length === 0 && (
            <Typography
              variant="body1"
              color="colors.gray04"
              sx={{ padding: '0 6px', fontWeight: 400 }}
            >
              No users added yet
            </Typography>
          )}
          {!!researchUsers
            && researchUsers?.map(researchUser => (
              <EmailItemBox
                key={researchUser.id}
                className={editMode ? 'active' : ''}
              >
                <Box display="flex" alignItems="center">
                  <AltLetterIcon />
                  <Typography
                    variant="subtitle2"
                    ml="12px"
                    mr="8px"
                    sx={{ fontWeight: 500, lineHeight: '29px' }}
                  >
                    {`${researchUser.firstName} ${researchUser.lastName} - ${researchUser.email}`}
                  </Typography>
                </Box>
                <DeleteForeverRoundedIcon
                  className="deleteIcon"
                  onClick={() => {
                    removeResearchUser(researchUser.id);
                  }}
                  sx={{ color: palette.colors.error03, fontSize: '22px' }}
                />
              </EmailItemBox>
            ))}
        </EmailBox>
      </Box>
    </form>
  );
};

export default OrganizationGeneralSettingsForm;
