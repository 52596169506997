/* eslint-disable import/order, no-unused-vars */
import React, { useRef, useState, useCallback } from 'react';

// * MUI
import { Box, IconButton, Typography } from '@mui/material';

// * Hooks & Utils
import palette from '../../theme/palette';
import useFileDrop from './useFileDrop';

// * Components
import { DnDBox } from './styledComponents';

// * Icons
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import { ReactComponent as BackupIcon } from '../../assets/icons/backupIcon.svg';
import { ReactComponent as DocumentIcon } from '../../assets/icons/documentIcon.svg';

// * Interfaces
import type { FileExt } from './useFileDrop';
import type { CallTypes } from '../../shared/types/transcript';

const { hostname } = window.location;

const configByCallType: Record<CallTypes, {
  allowedExtensions: FileExt[];
  errorMessage: string;
  inputAccept: string;
  helpText: string;
}> = {
  call: {
    allowedExtensions: hostname === 'app-dev.encorecompliance.com'
      ? ['pdf', 'docx', 'doc', 'wav']
      : ['pdf', 'docx', 'doc', 'mp3', 'm4a'],
    errorMessage: hostname === 'app-dev.encorecompliance.com'
      ? 'Failed to import due to unsupported file format, kindly use .wav, .pdf, .doc(x), and .txt files only.'
      : 'Failed to import due to unsupported file format, kindly use .mp3, .m4a, .pdf, .doc(x), and .txt files only.',
    inputAccept: hostname === 'app-dev.encorecompliance.com'
      ? '.pdf,.docx,.doc,.wav'
      : '.pdf,.docx,.doc,.mp3,.m4a',
    helpText: hostname === 'app-dev.encorecompliance.com'
      ? 'Only support .wav, .pdf and .doc(x) files'
      : 'Only support .mp3, .m4a, .pdf and .doc(x) files',
  },
  meeting: {
    allowedExtensions: hostname === 'app-dev.encorecompliance.com'
      ? ['wav']
      : ['mp3', 'm4a'],
    errorMessage: hostname === 'app-dev.encorecompliance.com'
      ? 'Failed to import due to unsupported file format, kindly use .wav files only.'
      : 'Failed to import due to unsupported file format, kindly use .mp3 and .m4a files only.',
    inputAccept: hostname === 'app-dev.encorecompliance.com'
      ? '.wav'
      : '.mp3,.m4a',
    helpText: hostname === 'app-dev.encorecompliance.com'
      ? 'Only support .wav files'
      : 'Only support .mp3 and .m4a files',
  },
  document: {
    allowedExtensions: ['txt'],
    errorMessage: 'Failed to import due to unsupported file format, kindly use .txt files only.',
    inputAccept: '.txt',
    helpText: 'Only support .txt files',
  },
};

interface TargetBoxProps {
  onDrop: (file: File) => void;
  onDelete: React.MouseEventHandler<HTMLButtonElement> | undefined;
  droppedFile: File | null;
  callType: keyof typeof configByCallType;
}

const TargetBox = ({
  onDrop, onDelete, droppedFile, callType,
}: TargetBoxProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const {
    allowedExtensions, errorMessage: errorText, inputAccept, helpText,
  } = configByCallType[callType];

  const handleValidFile = (isValid: boolean) => {
    setErrorMessage(isValid ? null : errorText);
  };

  const isValidFileExtension = useCallback((file: File): boolean => {
    const fileExtension = file.name.split('.').pop()?.toLowerCase();
    return Boolean(fileExtension && allowedExtensions.includes(fileExtension as FileExt));
  }, [allowedExtensions]);

  const handleOnDrop = (file: File) => {
    const isValid = isValidFileExtension(file);
    handleValidFile(isValid);
    if (isValid) onDrop(file);
  };

  const { makeDropable, canDrop, isOver } = useFileDrop({
    extensions: allowedExtensions,
    onDrop: handleOnDrop,
  });

  const isActive = canDrop && isOver;

  const handleBoxClick = useCallback(() => {
    inputRef.current?.click();
  }, []);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) handleOnDrop(file);
  };

  return (
    <div style={{ marginBottom: '48px' }}>
      <DnDBox
        ref={makeDropable}
        className={`${isActive ? 'active' : ''} ${droppedFile ? 'dropped' : ''} ${errorMessage ? 'error' : ''}`}
        onClick={handleBoxClick}
      >
        <input
          type="file"
          ref={inputRef}
          accept={inputAccept}
          style={{ display: 'none' }}
          onChange={handleFileChange}
        />
        {droppedFile ? (
          <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
            <Box display="flex" alignItems="center" width="100%">
              <Box display="flex">
                <DocumentIcon />
              </Box>
              <Box display="flex" flexDirection="column" ml="16px">
                <Typography
                  variant="body1"
                  mb="4px"
                  sx={{
                    fontWeight: 600, maxWidth: 640, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis',
                  }}
                >
                  {droppedFile.name}
                </Typography>
                <Typography variant="body1" color="colors.success03" sx={{ fontSize: '14px', fontWeight: 400, lineHeight: '20px' }}>
                  Uploaded
                </Typography>
              </Box>
            </Box>
            <IconButton sx={{ padding: 0 }} onClick={onDelete}>
              <DeleteOutlineOutlinedIcon sx={{ color: palette.colors.error03, fontSize: '32px' }} />
            </IconButton>
          </Box>
        ) : (
          <Box display="flex" alignItems="center">
            <BackupIcon />
            <Box display="flex" flexDirection="column" ml="32px">
              <Typography variant="body1" mb="8px" sx={{ fontSize: '14px', fontWeight: 400, lineHeight: '20px' }}>
                Drag your file or
                {' '}
                <Typography component="span" variant="subtitle2" color={palette.primary.main} sx={{ lineHeight: '20px', cursor: 'pointer' }}>
                  browse
                </Typography>
              </Typography>
              <Typography variant="body1" color="colors.gray05" sx={{ fontSize: '14px', fontWeight: 400, lineHeight: '20px' }}>
                {helpText}
              </Typography>
            </Box>
          </Box>
        )}
      </DnDBox>
      {errorMessage && (
        <Box display="flex" alignItems="center" mt="8px">
          <ErrorRoundedIcon sx={{ color: palette.colors.error04, fontSize: '20px' }} />
          <Typography variant="body1" color={palette.colors.error04} ml="8px" sx={{ fontSize: '14px', fontWeight: 400, lineHeight: '20px' }}>
            {errorMessage}
          </Typography>
        </Box>
      )}
    </div>
  );
};

export default TargetBox;
