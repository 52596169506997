import { gql } from '@apollo/client';

export const reviewFragment = gql`
  fragment ReviewFragment on ReviewType {
    uuid
    isAutocompleted
    liveChaperoneReviewer {
      id
      uuid
      firstName
      lastName
    }
    fullTranscriptReviewer {
      id
      uuid
      firstName
      lastName
    }
    reviewer {
      id
      uuid
      firstName
      lastName
    }
    status {
      key
      value
    }
    reviewNote {
      text
      updatedBy {
        email
        firstName
        lastName
      }
      updatedAt
    }
  }
`;

export const highlightFragment = gql`
  fragment HighlightFragment on HighlightType {
    uuid
    id
    reason
    paragraph {
      id
      uuid
      text
      cleanedOriginalText
      isTranslated
      originalText
      startTime
      endTime
      speaker
      participant {
        uuid
        name
        originalName
      }
      transcript {
        id
      }
      lastHistory {
        createdAt
        oldText
        newText
        editor {
          firstName
          lastName
        }
      }
    }
    highlightNotes {
      uuid
      text
      filename
      fileUrl
      updatedAt
      resolutionNoteCreationTime
      wasResolutionNote
      updatedBy {
        firstName
        lastName
      }
      createdAt
    }
    suggestions
    status {
      key
      value
    }
    reviewer {
      id
      uuid
      email
      firstName
      lastName
    }
    score
    categories
    phrase
    createdType {
      key
      value
    }
    createdBy {
      email
      firstName
      lastName
    }
  }
`;

export const transcriptFragment = gql`
  fragment TranscriptFragment on TranscriptType {
    uuid
    uid
    analysts
    experts
    pendingCount
    resolvedCount
    aiResolvedCount
    disableAudio
    conversation {
      uuid
    }
    status {
      key
      value
    }
    tickers {
        isPrimary
        ticker {
          exchange
          title
          companyName
        }
      }
    call {
      id
      uuid
      type
      name
      datetime
      topic
      sentiment
      account {
        uuid
        name
        settings {
          fullLogo
          avatarLogo
        }
      }
      callNotes {
        id
        uuid
        updatedAt
        isDeleted
        uid
        text
        addedBy {
          id
          uuid
          firstName
          lastName
        }
        createdAt
      }
      summary
      analystName
      projectId
      expertName
      expertPerspective
      expertEmploymentHistory
      expertConsultingSummary
      inSample
      inResearch
      network {
        key
        value
      }
      status {
        key
        value
      }
      transcript {
        audioUrl
        isFileAText
        status {
          key
          value
        }
      }
      review {
        ...ReviewFragment
      }
      relatedCalls {
        name
        datetime
        expertName
        type
        network {
          key
          value
        }
        transcript {
          uuid
        }
      }
    }
    highlights {
      ...HighlightFragment
    }
    paragraphs {
      uuid
      startTime
      endTime
      speaker
      participant {
        uuid
        name
        originalName
      }
      text
      cleanedOriginalText
      isTranslated
      originalText
      highlights {
        phrase
        status {
          key
          value
        }
      }
      lastHistory {
        createdAt
        oldText
        newText
        editor {
          firstName
          lastName
        }
      }
    }
    participants {
      uuid
      updatedAt
      name
      originalName
    }
    callAnalysts {
      uuid
      name
    }
    meetingParticipants {
      name
      email
    }
  }
  ${highlightFragment}
  ${reviewFragment}
`;

export const ALL_TRANSCRIPTS_QUERY = gql`
  query allTranscripts(
    $first: Int,
    $skip: Int,
    $completedTab: Boolean,
    $scheduledTab: Boolean,
    $reviewStatus: String,
    $dateRangeStart: String,
    $dateRangeEnd: String,
    $searchQuery: String,
    $analysts: [String],
    $complianceReviewerUuids: [String],
    $acaIoiAccountsUuids: [String],
    $networks: [String],
    $flags: [FlagType]
    $tickers: [String]
    $displayInResearchPortal: Boolean
  ) {
    allTranscripts(
      first: $first,
      skip: $skip,
      completedTab: $completedTab,
      scheduledTab: $scheduledTab,
      reviewStatus: $reviewStatus,
      dateRangeStart: $dateRangeStart,
      dateRangeEnd: $dateRangeEnd,
      searchQuery: $searchQuery,
      analysts: $analysts,
      complianceReviewerUuids: $complianceReviewerUuids,
      acaIoiAccountsUuids: $acaIoiAccountsUuids,
      networks: $networks,
      flags: $flags,
      tickers: $tickers
      displayInResearchPortal: $displayInResearchPortal
    ) {
      totalCount
      results {
        uuid
        uid
        pendingCount
        resolvedCount
        aiResolvedCount
        disableAudio
        status {
          key
          value
        }
        call {
          name
          type
          datetime
          expertName
          analystName
          inSample
          inResearch
          createdByResearchAnalyst
          account {
            uuid
            name
            settings {
              fullLogo
              avatarLogo
            }
          }
          network {
            key
            value
          }
          status {
            key
            value
          }
          transcript {
            status {
              key
              value
            }
          }
          review {
            uuid
            isAutocompleted
            reviewer {
              id
              uuid
              firstName
              lastName
            }
            status {
              key
              value
            }
          }
        }
      }
    }
  }
`;

export const TRANSCRIPT_QUERY = gql`
  query transcript($uuid: UUID!) {
    transcript(uuid: $uuid) {
      ...TranscriptFragment
    }
  }
  ${transcriptFragment}
`;

export const MORE_DETAIL_ITEM_QUERY = gql`
  query moreDetailItem($modelName: ModelNameEnum!, $modelId: Int!, $keyPhrase: String!) {
    moreDetailItem(modelName: $modelName, modelId: $modelId, keyPhrase: $keyPhrase) {
      jsonValue
    }
  }
`;

export const TRANSCRIPT_AI_CONVERSATION = gql`query transcriptAiConversation($uuid: UUID!) {
  transcriptAiConversation(uuid: $uuid) {
    isWaitingForResponse
    messages {
      role
      createdAt
      text
    }
  }
}`;

export const REOPEN_HIGHLIGHT_MUTATION = gql`
  mutation reopenHighlight($highlightUuid: String!) {
    reopenHighlight(highlightUuid: $highlightUuid) {
      status
      message
    }
  }
`;

export const COMPLETE_COMPLIANCE_REVIEW_MUTATION = gql`
  mutation completeComplianceReview($reviewUuid: UUID!, $reviewNote: String) {
    completeComplianceReview(reviewUuid: $reviewUuid, reviewNote: $reviewNote) {
      status
      message
    }
  }
`;

export const REOPEN_COMPLIANCE_REVIEW_MUTATION = gql`
  mutation reopenComplianceReview($reviewUuid: String!) {
    reopenComplianceReview(reviewUuid: $reviewUuid) {
      status
      message
    }
  }
`;

export const UPDATE_REVIEWERS_MUTATION = gql`
  mutation updateReviewers($reviewUuid: String!, $liveChaperoneReviewerUuid: String, $fullTranscriptReviewerUuid: String) {
    updateReviewers(reviewUuid: $reviewUuid, liveChaperoneReviewerUuid: $liveChaperoneReviewerUuid, fullTranscriptReviewerUuid: $fullTranscriptReviewerUuid) {
      status
      message
    }
  }
`;

export const POST_NOTE_MUTATION = gql`
  mutation postNote($highlightUuid: String!, $text: String!, $file: Upload, $resolve: Boolean) {
    postNote(highlightUuid: $highlightUuid, text: $text, file: $file, resolve: $resolve) {
      status
      message
    }
  }
`;

export const SUSPEND_CALL_MUTATION = gql`
  mutation suspendCall($transcriptUuid: String!) {
    suspendCall(transcriptUuid: $transcriptUuid) {
      status
      message
    }
  }
`;

export const RESUME_CALL_MUTATION = gql`
  mutation resumeCall($transcriptUuid: String!) {
    resumeCall(transcriptUuid: $transcriptUuid) {
      status
      message
    }
  }
`;

export const ADD_CALL_TO_SAMPLE_MUTATION = gql`
  mutation addCallToSample($transcriptUuid: String!) {
    addCallToSample(transcriptUuid: $transcriptUuid) {
      status
      message
    }
  }
`;

export const REMOVE_CALL_FROM_SAMPLE_MUTATION = gql`
  mutation removeCallFromSample($transcriptUuid: String!) {
    removeCallFromSample(transcriptUuid: $transcriptUuid) {
      status
      message
    }
  }
`;

export const GET_JSON_MUTATION = gql`
  mutation getJsonTranscript($transcriptUuid: UUID!) {
    getJsonTranscript(transcriptUuid: $transcriptUuid) {
      jsonObject
    }
  }
`;

export const GET_PDF_MUTATION = gql`
  mutation getPdfTranscript($transcriptUuid: UUID!) {
    getPdfTranscript(transcriptUuid: $transcriptUuid) {
      pdfBase64
    }
  }
`;

export const GET_DOCX_MUTATION = gql`
  mutation getDocxTranscript($transcriptUuid: UUID!) {
    getDocxTranscript(transcriptUuid: $transcriptUuid) {
      docString
    }
  }
`;

export const DISPLAY_IN_RESEARCH_PORTAL_MUTATION = gql`
  mutation displayInResearchPortal($transcriptUuid: UUID!, $inResearch: Boolean!) {
    displayInResearchPortal(transcriptUuid: $transcriptUuid, inResearch: $inResearch) {
      status
      message
    }
  }
`;

export const SEND_MESSAGE_TO_AI_CONVERSATION = gql`
  mutation sendMessageToAiConversation($conversationUuid: UUID!, $message: String!) {
    sendMessageToAiConversation(conversationUuid: $conversationUuid, message: $message) {
      status
      message
    }
  }
`;

export const DISABLE_AUDIO_IN_RESEARCH_PORTAL_MUTATION = gql`
  mutation disableAudioInResearchPortal($transcriptUuid: UUID!, $disableAudio: Boolean!) {
    disableAudioInResearchPortal(transcriptUuid: $transcriptUuid, disableAudio: $disableAudio) {
      status
      message
    }
  }
`;

export const UPDATE_PARAGRAPH_MUTATION = gql`
  mutation updateParagraph($paragraphUuid: UUID!, $newText: String!) {
    updateParagraph(paragraphUuid: $paragraphUuid, newText: $newText) {
      lastHistory {
        createdAt
        oldText
        newText
        editor {
          firstName
          lastName
        }
      }
    }
  }
`;

export const UPDATE_PARTICIPANTS_MUTATION = gql`
  mutation updateParticipants($updates: [ParticipantUpdateInput!]!) {
    updateParticipants(updates: $updates) {
      status
      message
    }
  }
`;

export const ASSIGN_USER_TO_REVIEW_MUTATION = gql`
  mutation assignUserToReview($instanceUuid: UUID!, $userUuid: UUID) {
    assignUserToReview(instanceUuid: $instanceUuid, userUuid: $userUuid) {
      status
      message
      reviewer {
        id
        uuid
        firstName
        lastName
      }
    }
  }
`;

export const ASSIGN_USER_TO_HIGHLIGHT_MUTATION = gql`
  mutation assignHighlightReviewer($instanceUuid: UUID!, $userUuid: UUID) {
    assignHighlightReviewer(instanceUuid: $instanceUuid, userUuid: $userUuid) {
      status
      message
    }
  }
`;

export const UPDATE_CALL_ANALYSTS_MUTATION = gql`
  mutation updateCallAnalysts($transcriptUuid: UUID!, $analystsUuid: [UUID]!) {
    updateCallAnalysts(transcriptUuid: $transcriptUuid, analystsUuid: $analystsUuid) {
      status
      message
    }
  }
`;

export const CREATE_HIGHLIGHT_MUTATION = gql`
  mutation createHighlight(
    $confidence: Int!,
    $categories: [String!]!,
    $phrase: String!,
    $paragraphUuid: String!
  ) {
    createHighlight(
      confidence: $confidence,
      categories: $categories,
      phrase: $phrase,
      paragraphUuid: $paragraphUuid
    ) {
      status
      message
    }
  }
`;

export const CREATE_CALL_NOTE_MUTATION = gql`
  mutation createCallNote($callUuid: UUID!, $text: String!) {
    createCallNote(callUuid: $callUuid, text: $text) {
      status
      message
    }
  }
`;

export const REMOVE_CALL_NOTE_MUTATION = gql`
  mutation removeCallNote($noteUuid: UUID!) {
    removeCallNote(noteUuid: $noteUuid) {
      status
      message
    }
  }
`;

export const UPDATE_LIVE_CHAPERONE_REVIEWER_MUTATION = gql`
  mutation updateLiveChaperoneReviewer($reviewUuid: String!, $assignReviewer: Boolean!) {
    updateLiveChaperoneReviewer(reviewUuid: $reviewUuid, assignReviewer: $assignReviewer) {
      status
      message
    }
  }
`;

export const UPDATE_FULL_TRANSCRIPT_REVIEWER_MUTATION = gql`
  mutation updateFullTranscriptReviewer($reviewUuid: String!, $assignReviewer: Boolean!) {
    updateFullTranscriptReviewer(reviewUuid: $reviewUuid, assignReviewer: $assignReviewer) {
      status
      message
    }
  }
`;
