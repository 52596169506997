import { gql } from '@apollo/client';

export const MY_TOPICS_QUERY = gql`
  query myTopics {
    myTopics {
      totalCount
      results {
        uuid
        name
        callsCount
      }
    }
  }
`;

export const TOPIC_QUERY = gql`
  query topic($uuid: UUID!) {
    topic(uuid: $uuid) {
      id
      uuid
      name
      status {
        key
      }
      callsCount
      jsonSummary
      conversation {
        uuid
      }
      calls {
        id
        uuid
        datetime
        name
        analystName
        expertName
        projectId
        network {
          value
        }
      }
      relatedCompanies {
        isPrimary
        ticker {
          exchange
          title
          companyName
        }
      }
    }
  }
`;
