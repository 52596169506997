/* eslint-disable import/order, no-unused-vars */
import React, {
  useCallback, useEffect, useState, useContext,
} from 'react';

// * MUI
import {
  Box, Typography, Divider, Grid,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

// * Components
import TranscriptView from '../../components/TranscriptView';
import AudioPlayer from '../../components/AudioPlayer';
import MoveToTopButton from '../../components/MoveToTopButton';
import SuspendRecord from '../../components/SuspendRecord';
import AddToSample from '../../components/AddToSample';
import RelatedCalls from '../../components/RelatedCalls';
import EditSpeakers from '../../components/EditSpeakers';
import AnalystDropdown from '../../components/AnalystDropdown';
import { CallDetailsItem, ComplianceReviewer } from '../../components';
import {
  MainContainer,
  ScrollContainer,
  HighlightDot,
  HighlightStatus,
  AudioSmallBox,
  StickyBox,
} from './styledComponents';
import { AvatarLarge } from '../../components/AccountSwitcher/styledComponents';
import ReviewFlags from './ReviewFlags';

// * Icons
import { ReactComponent as NoteIcon } from '../../assets/icons/noteIcon.svg';

// * Hooks & Utils
import { isEmpty } from 'lodash';
import { useMutation, useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import { useMe, AppContext } from '../../components/AppContextProvider';
import { formatDateTimeToUserTimezone } from '../../shared/utils/dateHelpers';

// * Queries & Mutations
import { ASSIGN_USER_TO_REVIEW_MUTATION, TRANSCRIPT_QUERY, UPDATE_CALL_ANALYSTS_MUTATION } from '../../queries/transcript/transcript';
import { ACCOUNT_ANALYSTS_QUERY } from '../../queries/calls/accountAnalysts';

// * Interfaces
import type {
  ParagraphHistoryType, ParagraphType, ParticipantType, TranscriptType, HighlightType,
  CallTypes,
} from '../../shared/types/transcript';
import type { AnalystType } from '../../shared/types/calls';

type CallDetailsProps = {
  transcript: TranscriptType;
  selectedHighlight: HighlightType | null;
  setSelectedHighlight: React.Dispatch<React.SetStateAction<HighlightType | null>>;
  isScheduledTab: boolean;
  accountName: string;
  accountAvatarLogo: string;
  audio: HTMLAudioElement | null;
  isPlayingMain: boolean;
  setIsPlayingMain: React.Dispatch<React.SetStateAction<boolean>>;
  isPlayingParagraph: boolean;
  setIsPlayingParagraph: React.Dispatch<React.SetStateAction<boolean>>;
  inSample: boolean;
  onSelect: (uuid: string) => void;
  updateCallComplianceReviewerInCompletedTranscripts?: (updatedTranscriptUuid: TranscriptType['uuid'], updatedReviewer: TranscriptType['call']['review']['reviewer']) => void;
};

const CallDetails = ({
  transcript, selectedHighlight, setSelectedHighlight, isScheduledTab, accountName, accountAvatarLogo,
  audio, isPlayingMain, setIsPlayingMain, isPlayingParagraph, setIsPlayingParagraph, inSample, onSelect,
  updateCallComplianceReviewerInCompletedTranscripts,
}: CallDetailsProps) => {
  const { me } = useMe();
  const { refetchCompletedCalls, refetchReviewedCalls, refetchScheduledCalls } = useContext(AppContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { isResearchAnalyst } = me;
  const { call } = transcript;
  const callStatus = call.status.value;
  const isTranscriptCompleted = transcript.status.value === 'Completed';

  const [transcriptParagraphs, setTranscriptParagraphs] = useState<ParagraphType[]>(transcript.paragraphs);
  const [transcriptParticipants, setTranscriptParticipants] = useState<ParticipantType[]>(transcript.participants);
  const [transcriptCallAnalysts, setTranscriptCallAnalysts] = useState<AnalystType[]>(transcript.callAnalysts);

  const isCall = call.type === 'call';

  const {
    data: { accountAnalysts = [] } = { accountAnalysts: [] },
  } = useQuery<{ accountAnalysts: AnalystType[] }>(
    ACCOUNT_ANALYSTS_QUERY,
    { variables: { accountUuid: call.account.uuid } },
  );

  const [updateCallAnalysts] = useMutation(UPDATE_CALL_ANALYSTS_MUTATION);

  const handleUpdateCallAnalysts = useCallback(
    async (analysts: AnalystType[]) => {
      setTranscriptCallAnalysts(analysts);
      await updateCallAnalysts({
        variables: {
          transcriptUuid: transcript.uuid,
          analystsUuid: analysts.map(analyst => analyst.uuid),
        },
      });
      await refetchScheduledCalls?.();
      await refetchReviewedCalls?.();
      await refetchCompletedCalls?.();
    },
    [
      transcript.uuid,
      updateCallAnalysts,
      refetchScheduledCalls,
      refetchReviewedCalls,
      refetchCompletedCalls,
    ],
  );

  const [assignUser] = useMutation(ASSIGN_USER_TO_REVIEW_MUTATION, {
    onCompleted: ({ assignUserToReview }: {
      assignUserToReview: {
        message: string
        reviewer: TranscriptType['call']['review']['reviewer']
      }
    }) => {
      refetchCompletedCalls?.();
      refetchReviewedCalls?.();
      toast.success(assignUserToReview.message);

      updateCallComplianceReviewerInCompletedTranscripts?.(transcript.uuid, assignUserToReview.reviewer);
    },
    refetchQueries: [
      { query: TRANSCRIPT_QUERY, variables: { uuid: transcript.uuid } },
    ],
  });

  const handleEditParticipants = useCallback(
    (updatedParticipants: ParticipantType[]) => {
      setTranscriptParticipants(updatedParticipants);

      const paragraphsCopy = transcriptParagraphs.map(paragraph => {
        const participant = updatedParticipants.find(p => p.uuid === paragraph.participant?.uuid);

        return participant ? { ...paragraph, participant } : paragraph;
      });

      setTranscriptParagraphs(paragraphsCopy);

      // TODO: ContextApi must be used to share the transcript data on top of this
      transcript.highlights.forEach(highlight => {
        const updatedParagraph = paragraphsCopy.find(
          paragraph => paragraph.uuid === highlight.paragraph.uuid,
        );
        if (!updatedParagraph) return;

        // eslint-disable-next-line no-param-reassign
        highlight.paragraph = updatedParagraph;
      });
    },
    [transcript.highlights, transcriptParagraphs],
  );

  const updateParagraphText = useCallback((paragraphUuid: string, newLastHistory: ParagraphHistoryType) => {
    setTranscriptParagraphs(prevState => prevState.map(paragraph => {
      if (paragraph.uuid !== paragraphUuid) return paragraph;

      return { ...paragraph, text: newLastHistory.newText, lastHistory: newLastHistory };
    }));
  }, []);

  const handleScroll = () => {
    const headerHeight = 160;
    const anchor = document.getElementById('PlayerAnchor') as HTMLElement;
    const staticPlayer = document.getElementById('StaticAudioPlayer') as HTMLElement;
    const stickyPlayer = document.getElementById('StickyAudioPlayer') as HTMLElement;
    let playerShouldSticky;
    if (anchor && staticPlayer && stickyPlayer) {
      playerShouldSticky = anchor.getBoundingClientRect().top < headerHeight;
      if (playerShouldSticky) {
        staticPlayer.style.display = 'none';
        stickyPlayer.style.display = 'flex';
      } else {
        staticPlayer.style.display = 'flex';
        stickyPlayer.style.display = 'none';
      }
    }
  };

  const renderTickers = (tickers: TranscriptType['tickers']) => (
    <CallDetailsItem title="Related Company(s)">
      {tickers
        .filter(ticker => ticker.isPrimary)
        .map(ticker => {
          const { title, companyName, exchange } = ticker.ticker;

          return (
            <Typography key={title} variant="subtitle1" color="colors.gray08" sx={{ textTransform: 'none' }}>
              {exchange ? `${companyName} (${exchange}:${title})` : `${companyName} (${title})`}
            </Typography>
          );
        })}
    </CallDetailsItem>
  );

  const typeLabel: Record<CallTypes, string> = {
    meeting: 'Meeting',
    call: 'Expert Call',
    document: 'Document',
  };

  useEffect(() => {
    setTranscriptParagraphs(transcript.paragraphs);
    setTranscriptParticipants(transcript.participants);
  }, [transcript.paragraphs, transcript.participants]);

  return (
    <MainContainer>
      {isMobile && !call.transcript.isFileAText && (
        <Box display="flex" alignItems="center" justifyContent="center" width="100%">
          <AudioSmallBox>
            <AudioPlayer
              audio={audio}
              isPlayingMain={isPlayingMain}
              setIsPlayingMain={setIsPlayingMain}
              isPlayingParagraph={isPlayingParagraph}
              setIsPlayingParagraph={setIsPlayingParagraph}
              stickyPlayer
              playParagraph={false}
            />
          </AudioSmallBox>
        </Box>
      )}
      {!isScheduledTab && !isMobile && !call.transcript.isFileAText && (
        <StickyBox id="StickyAudioPlayer">
          <Box display="flex" alignItems="center">
            <NoteIcon />
            <Typography
              variant="subtitle1"
              color="colors.grey10"
              sx={{ marginLeft: '12px' }}
            >
              Transcript
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <AudioSmallBox>
              <AudioPlayer
                audio={audio}
                isPlayingMain={isPlayingMain}
                setIsPlayingMain={setIsPlayingMain}
                isPlayingParagraph={isPlayingParagraph}
                setIsPlayingParagraph={setIsPlayingParagraph}
                stickyPlayer
                playParagraph={false}
              />
            </AudioSmallBox>
            <MoveToTopButton />
          </Box>
        </StickyBox>
      )}
      <ScrollContainer
        onScroll={handleScroll}
        id="scrollableRightColumn"
        sx={{ padding: isMobile ? '12px 8px' : '12px 20px 12px 13px' }}
      >
        <Box sx={{ padding: '0 12px' }}>
          <Typography
            variant={isScheduledTab ? 'h5' : 'subtitle1'}
            color="colors.gray10"
            m={isScheduledTab ? '8px 0 12px' : 0}
          >
            {`${typeLabel[call.type]} Details`}
          </Typography>
          {!isScheduledTab && (
            <Divider flexItem sx={{ margin: '12px 0', borderColor: 'colors.gray03' }} />
          )}
        </Box>
        {me.isAcaReviewer && (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', padding: '0 12px' }}>
            <CallDetailsItem title="Client Name" sx={{ marginBottom: '12px' }}>
              <Box display="flex" alignItems="center" textTransform="capitalize">
                {accountAvatarLogo && <AvatarLarge alt={accountName} src={accountAvatarLogo} />}
                <Typography
                  variant="overline"
                  color="colors.gray08"
                  sx={{ textTransform: 'none', whiteSpace: 'nowrap' }}
                >
                  {accountName}
                </Typography>
              </Box>
            </CallDetailsItem>
          </Box>
        )}
        <Box sx={{ display: 'flex', flexWrap: 'wrap', padding: '0 12px' }}>
          <CallDetailsItem title="Date & Time" sx={{ marginRight: '120px', marginBottom: '12px' }}>
            <Box display="flex" alignItems="center">
              {(isScheduledTab && (callStatus === 'In Progress' || callStatus === 'Canceled')) && (
                <HighlightDot sx={{ backgroundColor: callStatus === 'Canceled' ? 'colors.warning04' : 'colors.success04' }} />
              )}
              <Typography
                variant="overline"
                color="colors.gray08"
                sx={{ textTransform: 'none' }}
              >
                {formatDateTimeToUserTimezone(call.datetime, me.timezone)}
              </Typography>
              {(isScheduledTab && (callStatus === 'In Progress' || callStatus === 'Canceled')) && (
                <HighlightStatus
                  variant="body1"
                  color={callStatus === 'Canceled' ? 'colors.warning04' : 'colors.success04'}
                >
                  {callStatus}
                </HighlightStatus>
              )}
            </Box>
          </CallDetailsItem>
          {!isScheduledTab && (
            <ReviewFlags review={call.review} disabledBtn={false} call={call} me={me} />
          )}
          {(isScheduledTab && inSample && (callStatus === 'Scheduled' || callStatus === 'Suspended')) && (
            <CallDetailsItem title="Record Terms" sx={{ marginBottom: '12px' }}>
              <SuspendRecord
                status={callStatus}
                title={call.name}
                callDate={call.datetime}
                transcriptUuid={transcript.uuid}
              />
            </CallDetailsItem>
          )}
          {isScheduledTab && !inSample && callStatus !== 'Canceled' && (
            <CallDetailsItem title="Record Terms" sx={{ marginBottom: '12px' }}>
              <AddToSample transcriptUuid={transcript.uuid} callDate={call.datetime} />
            </CallDetailsItem>
          )}
        </Box>
        <Grid container spacing="12px" sx={{ padding: '0 12px', flexWrap: 'wrap' }}>
          {call.type === 'meeting' && (
            <Grid item xs={12}>
              <CallDetailsItem title="Meeting Participants">
                <Box display="flex" flexWrap="wrap" mt="8px">
                  {Array.isArray(transcript.meetingParticipants) && transcript.meetingParticipants.length > 0 ? (
                    [...transcript.meetingParticipants]
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map((participant, index) => (
                        <Box key={participant.email} display="flex" flexWrap="nowrap" mr="4px">
                          <Typography
                            variant="subtitle1"
                            color="colors.gray08"
                            mr="3px"
                          >
                            {`${participant.name} |`}
                          </Typography>
                          <Typography
                            variant="subtitle1"
                            color="colors.gray08"
                            sx={{ fontWeight: 400 }}
                          >
                            {participant.email}
                            {index < transcript.meetingParticipants.length - 1 && ','}
                          </Typography>
                        </Box>
                      ))
                  ) : (
                    <Typography variant="subtitle1" color="colors.gray08">
                      Not available
                    </Typography>
                  )}
                </Box>
              </CallDetailsItem>
            </Grid>
          )}
          {call.type === 'call' && (
            <>
              <Grid item xs={12} md={6}>
                <CallDetailsItem title="Project ID" isTypography>
                  {transcript.call.projectId || 'Not Available'}
                </CallDetailsItem>
              </Grid>
              <Grid item xs={12} md={6}>
                <CallDetailsItem title="Expert Name" isTypography>
                  {call.expertName || 'Not Available'}
                </CallDetailsItem>
              </Grid>
              <Grid item xs={12} md={6}>
                <CallDetailsItem title="Expert Perspective" isTypography>
                  {call.expertPerspective || 'Not Available'}
                </CallDetailsItem>
              </Grid>
            </>
          )}
          {isCall && (
            <Grid item xs={12} md={6}>
              <CallDetailsItem title="Expert Network" isTypography>
                {call.network.value}
              </CallDetailsItem>
            </Grid>
          )}
          {(isTranscriptCompleted && !isScheduledTab) && (
            <Grid item xs={12} md={6}>
              <CallDetailsItem title="Compliance Reviewer">
                <ComplianceReviewer
                  assignUser={assignUser}
                  instanceUuid={call.review.uuid}
                  initialAssignee={call.review.reviewer}
                  signedInUserUuid={me.uuid}
                  placeholder="Unassigned"
                  disabled={call.review.status.value === 'Reviewed'}
                />
              </CallDetailsItem>
            </Grid>
          )}
          {call.type === 'call' && (
            <>
              <Grid item xs={12} md={6}>
                <CallDetailsItem title="Analyst(s)">
                  {call.review.status.value === 'Reviewed' ? (
                    <Box display="flex" alignItems="center" minHeight={36}>
                      <Typography variant="subtitle2" color="colors.gray08" sx={{ fontSize: '16px', textTransform: 'none' }}>
                        {Array.isArray(transcriptCallAnalysts) && transcriptCallAnalysts.length > 0 ? (
                          [...transcriptCallAnalysts]
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .map((analyst, index, array) => (
                              <React.Fragment key={analyst.email}>
                                {analyst.name}
                                {index < array.length - 1 && ', '}
                              </React.Fragment>
                            ))
                        ) : (
                          'Not Available'
                        )}
                      </Typography>
                    </Box>
                  ) : (
                    <AnalystDropdown
                      handleUpdateCallAnalysts={handleUpdateCallAnalysts}
                      accountAnalysts={accountAnalysts}
                      callAnalysts={transcriptCallAnalysts}
                    />
                  )}
                </CallDetailsItem>
              </Grid>
              <Grid item xs={12}>
                <CallDetailsItem title="Employment History">
                  <Typography
                    variant="body1"
                    color="colors.gray08"
                    sx={{ marginLeft: '28px', fontWeight: 400, whiteSpace: 'break-spaces' }}
                  >
                    {call.expertEmploymentHistory || 'Not Available'}
                  </Typography>
                </CallDetailsItem>
              </Grid>
              <Grid item xs={12}>
                <CallDetailsItem title="Expert Consulting Summary">
                  <Typography
                    variant="body1"
                    color="colors.gray08"
                    sx={{ marginLeft: '28px', fontWeight: 400, whiteSpace: 'break-spaces' }}
                  >
                    {call.expertConsultingSummary || 'Not Available'}
                  </Typography>
                </CallDetailsItem>
              </Grid>
            </>
          )}
          {(call.topic || call.sentiment || call.summary) && (
            <Grid item xs={12}>
              <Divider flexItem sx={{ margin: '12px 0px', borderColor: 'colors.gray03' }} />
            </Grid>
          )}
          {!isScheduledTab && (
            <>
              <Grid item xs={12} md={6}>
                <CallDetailsItem title="Topic" isTypography>
                  {call.topic || 'Not Available'}
                </CallDetailsItem>
              </Grid>
              <Grid item xs={12} md={6}>
                <CallDetailsItem title="Sentiment" isTypography>
                  {call.sentiment || 'Not Available'}
                </CallDetailsItem>
              </Grid>
            </>
          )}
          {(call.summary && !isScheduledTab) && (
            <Grid item xs={12}>
              <CallDetailsItem title={`${typeLabel[call.type]} Summary`}>
                <Typography variant="body1" color="colors.gray08" mt="4px" sx={{ fontWeight: 400, whiteSpace: 'break-spaces' }}>
                  {call.summary}
                </Typography>
              </CallDetailsItem>
            </Grid>
          )}
        </Grid>
        {!isResearchAnalyst && transcript.tickers?.length > 0 && (
          <Box sx={{ padding: isMobile ? 0 : '0 12px', margin: '12px 0' }}>
            {renderTickers(transcript.tickers)}
          </Box>
        )}
        {(isResearchAnalyst && call.relatedCalls && !isEmpty(call.relatedCalls)) && (
          <Box sx={{ padding: isMobile ? 0 : '0 12px' }}>
            <RelatedCalls relatedCalls={call.relatedCalls} onSelect={onSelect} />
          </Box>
        )}
        {isResearchAnalyst && transcript.tickers?.length > 0 && (
          <Box sx={{ padding: isMobile ? 0 : '0 12px', margin: '12px 0' }}>
            {renderTickers(transcript.tickers)}
          </Box>
        )}
        {(!isScheduledTab && callStatus === 'Completed') && (
          <>
            <Box sx={{ padding: '0 12px' }}>
              <Divider flexItem sx={{ margin: '25px 0 16px', borderColor: 'colors.gray03' }} />
              <Box display="flex" alignItems="center" justifyContent="space-between" mb="24px">
                <Box display="flex" alignItems="center">
                  <NoteIcon />
                  <Typography variant="subtitle1" color="colors.grey10" sx={{ marginLeft: '12px' }}>
                    Transcript
                  </Typography>
                </Box>
                {transcript.participants.length > 0 && call.review.status.value !== 'Reviewed' && (
                  <EditSpeakers participants={transcriptParticipants} handleEditParticipants={handleEditParticipants} />
                )}
              </Box>
            </Box>
            <TranscriptView
              transcript={transcript}
              transcriptParagraphs={transcriptParagraphs}
              updateParagraphText={updateParagraphText}
              selectedHighlight={selectedHighlight}
              setSelectedHighlight={setSelectedHighlight}
              audio={audio}
              isPlayingMain={isPlayingMain}
              setIsPlayingMain={setIsPlayingMain}
              isPlayingParagraph={isPlayingParagraph}
              setIsPlayingParagraph={setIsPlayingParagraph}
              isResearchAnalyst={isResearchAnalyst}
            />
          </>
        )}
      </ScrollContainer>
    </MainContainer>
  );
};

CallDetails.defaultProps = {
  updateCallComplianceReviewerInCompletedTranscripts: undefined,
};

export default CallDetails;
