/* eslint-disable no-unused-vars */
import React from 'react';

// * MUI
import {
  Box, Button, IconButton, Grid, Typography,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

// * Components
import { Cell, ListHeaderContainer, CallListCard } from '../styledComponents';
import palette from '../../../../theme/palette';

// * Icons
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/deleteIcon.svg';

// * Utils
import { formatDateTimeToUserTimezone } from '../../../../shared/utils/dateHelpers';

// * Interfaces
import type { Call } from '../../types';

interface TableColumn {
  field: keyof Call
  headerName: string
  flex: string
}

const columns: TableColumn[] = [
  { field: 'datetime', headerName: 'Content Date & Time', flex: '1' },
  { field: 'name', headerName: 'Content Topic', flex: '3' },
  { field: 'projectId', headerName: 'Project ID', flex: '1' },
  { field: 'analystName', headerName: 'Analyst', flex: '1' },
  { field: 'expertName', headerName: 'Expert', flex: '1' },
  { field: 'expertNetwork', headerName: 'Expert Network', flex: '1' },
];

export const CallsListHeader = () => (
  <ListHeaderContainer>
    {columns.map(column => (
      <Cell key={column.field} sx={{ flex: column.flex }}>
        {column.headerName}
      </Cell>
    ))}
    <Box sx={{ flexBasis: '100px', textAlign: 'right' }}>
      <Typography
        color="colors.gray08"
        sx={{ fontSize: '14px', lineHeight: '22px' }}
      >
        Action
      </Typography>
    </Box>
  </ListHeaderContainer>
);

const CallAction = ({
  call,
  action,
  handleActionClick,
}: {
  call: Call
  action: 'add' | 'delete'
  handleActionClick: (_call: Call) => void
}) => (
  <Box sx={{ flexBasis: '100px', textAlign: 'right' }}>
    {action === 'add' ? (
      <Button
        variant="outlined"
        aria-label="add"
        startIcon={<CheckIcon />}
        onClick={() => handleActionClick(call)}
      >
        Add
      </Button>
    ) : (
      <IconButton
        color="secondary"
        aria-label="delete"
        onClick={() => handleActionClick(call)}
        sx={{ padding: '4px' }}
      >
        <DeleteIcon />
      </IconButton>
    )}
  </Box>
);

interface HighlightedTextProps {
  text: string
  highlight: string
}

const HighlightedText: React.FC<HighlightedTextProps> = ({
  text,
  highlight,
}) => {
  const parts = text.split(new RegExp(`(${highlight})`, 'gi'));

  return (
    <>
      {parts.map((part, index) => {
        const key = `${part}-${index}`;

        return part.toLowerCase() === highlight.toLowerCase() ? (
          <span key={key} style={{ color: '#1A73E8', fontWeight: 700 }}>
            {part}
          </span>
        ) : (
          <span key={key}>{part}</span>
        );
      })}
    </>
  );
};

interface CallListCardItemProps {
  size: number
  title: string
  children: React.ReactNode
  mb: string
}

const CallListCardItem: React.FC<CallListCardItemProps> = ({
  size,
  title,
  children,
  mb,
}) => (
  <Grid item xs={size} display="flex" flexDirection="column" mb={mb}>
    <Box
      sx={{
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '12px',
        color: palette.colors.gray08,
        marginBottom: '4px',
      }}
    >
      {title}
    </Box>
    <Box
      sx={{
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '18px',
        color: palette.colors.gray08,
        boxSizing: 'border-box',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      }}
    >
      {children}
    </Box>
  </Grid>
);

const HIGHLIGHTED_FIELDS: TableColumn['field'][] = ['name', 'projectId', 'expertName'];

interface CallsListBaseProps {
  calls: Call[]
  action: 'add' | 'delete'
  handleActionClick: (call: Call) => void
  searchTerm: string
  enableHighlighting: boolean
  userTimezone: string
}

interface MobileCallsListProps extends CallsListBaseProps {}

export const MobileCallsList: React.FC<MobileCallsListProps> = ({
  calls,
  action,
  handleActionClick,
  searchTerm,
  enableHighlighting,
  userTimezone,
}) => (
  <>
    {calls.map(call => (
      <CallListCard key={call.id}>
        <Grid container rowSpacing="6px" columnSpacing="8px">
          <CallListCardItem size={6} title="Content Date & Time" mb="4px">
            {formatDateTimeToUserTimezone(call.datetime, userTimezone)}
          </CallListCardItem>
          <Grid item xs={6} display="flex" justifyContent="end" mb="4px">
            <CallAction
              call={call}
              action={action}
              handleActionClick={handleActionClick}
            />
          </Grid>
          <CallListCardItem size={12} title="Content Topics" mb="4px">
            {enableHighlighting && HIGHLIGHTED_FIELDS.includes('name') ? (
              <HighlightedText text={call.name} highlight={searchTerm} />
            ) : (
              call.name
            )}
          </CallListCardItem>
          <CallListCardItem size={4} title="Project ID" mb="0">
            {enableHighlighting && HIGHLIGHTED_FIELDS.includes('projectId') ? (
              <HighlightedText
                text={call.projectId || 'N/A'}
                highlight={searchTerm}
              />
            ) : (
              call.projectId || 'N/A'
            )}
          </CallListCardItem>
          <CallListCardItem size={4} title="Expert Name" mb="0">
            {enableHighlighting && HIGHLIGHTED_FIELDS.includes('expertName') ? (
              <HighlightedText
                text={call.expertName || 'N/A'}
                highlight={searchTerm}
              />
            ) : (
              call.expertName || 'N/A'
            )}
          </CallListCardItem>
          <CallListCardItem size={4} title="Expert Network" mb="0">
            {call.type === 'meeting' ? 'N/A' : (call.expertNetwork || 'N/A')}
          </CallListCardItem>
        </Grid>
      </CallListCard>
    ))}
  </>
);

interface CallsListProps extends CallsListBaseProps {}

export const CallsList: React.FC<CallsListProps> = ({
  calls,
  action,
  handleActionClick,
  searchTerm,
  enableHighlighting,
  userTimezone,
}) => (
  <>
    {calls.map(call => (
      <Box key={call.id} sx={{ display: 'flex', alignItems: 'center', padding: '14px 20px' }}>
        {columns.map(column => {
          let cellValue;

          if (call.type === 'meeting' && column.field === 'expertNetwork') {
            cellValue = 'N/A';
          } else if (column.field === 'datetime') {
            cellValue = formatDateTimeToUserTimezone(call[column.field as keyof Call], userTimezone);
          } else {
            cellValue = call[column.field as keyof Call] ? `${call[column.field as keyof Call]}` : 'N/A';
          }

          return (
            <Cell key={column.field} sx={{ flex: column.flex }} title={cellValue}>
              {enableHighlighting && HIGHLIGHTED_FIELDS.includes(column.field)
                ? (
                  <HighlightedText text={cellValue} highlight={searchTerm} />)
                : (cellValue)}
            </Cell>
          );
        })}

        <CallAction call={call} action={action} handleActionClick={handleActionClick} />
      </Box>
    ))}
  </>
);
