// * Interfaces
import type { Call, Topic } from './types';
import type { FilterAccountCallsBySearchAndTopicType } from '../../shared/types/calls';
import type { TopicType } from '../../shared/types/topics';

const mapCallDetails = (call: {
  id: string
  uuid: string
  datetime: string
  name: string
  analystName: string
  expertName: string
  projectId: string
  type: 'call' | 'meeting'
  network: {
    value: string
  }
}): Call => ({
  ...call,
  datetime: new Date(call.datetime),
  expertNetwork: call.network.value,
});

export const mapTopicCalls = (topic: TopicType): Topic => ({
  ...topic,
  status: topic.status.key,
  calls: topic.calls.map(mapCallDetails),
});

export const mapAccountCalls = (
  calls: FilterAccountCallsBySearchAndTopicType['filterAccountCallsBySearchAndTopic']['results'],
): Call[] => calls.map(mapCallDetails);
