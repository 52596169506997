import { gql } from '@apollo/client';

export const CALL_MUTATION = gql`
  mutation call($input: CallMutationInput!) {
    call(input: $input) {
      call {
        uid
        uuid
        type
        status {
          key
          value
        }
        name
        inSample
        inResearch
        datetime
        analystName
        analystEmail
        expertName
        network {
          key
          value
        }
        expertPerspective
        expertEmploymentHistory
        expertConsultingSummary
        transcript {
          uuid
        }
      }
      errors {
        field
        messages
      }
    }
  }
`;

export const UPLOAD_CALL_FILE_MUTATION = gql`
  mutation uploadCallFile($callUuid: UUID!, $file: Upload!) {
    uploadCallFile(callUuid: $callUuid, file: $file) {
      message
      status
    }
  }
`;
