/* eslint-disable import/order, no-unused-vars, no-console */
import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';

// * MUI
import {
  Box,
  Divider,
  Typography,
  Grid,
  CircularProgress,
  RadioGroup,
  FormControlLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

// * Hooks & Utils
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useMutation, useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { AppContext, useMe } from '../AppContextProvider';
import paths from '../../paths/paths';
import palette from '../../theme/palette';

// * Components
import {
  DrawerContainer,
  ScrollContainer,
  FooterBlock,
  AddButton,
  CloseButton,
  ImportFullButton,
  DatePickerField,
  TimePickerField,
  TextInputField,
  BlurBox,
  CustomRadio,
  SelectInput,
  MenuProps,
  NameContainer,
} from './styledComponents';
import TargetBox from './TargetBox';

// * Icons
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { ReactComponent as AddCircleIcon } from '../../assets/icons/addCircleIcon.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/closeIcon.svg';

// * Queries
import { ALL_NETWORKS_QUERY } from '../../queries/calls/allNetworks';

// * Mutations
import { CALL_MUTATION, UPLOAD_CALL_FILE_MUTATION } from '../../queries/calls/mutations';

// * Interfaces
import type { Dayjs } from 'dayjs';
import type { NetworksType } from '../../shared/types/networks';
import type { CallTypes } from '../../shared/types/transcript';

interface IFormInput {
  type: CallTypes;
  callDate: Dayjs | null;
  callTime: Dayjs | null;
  name: string;
  expertName: string;
  analystName: string;
  expertPerspective: string;
  expertNetwork: string;
  expertEmploymentHistory: string;
  expertConsultingSummary: string;
}

const FieldLabel = ({ title, required }: { title: string; required?: boolean; }) => (
  <Typography
    variant="body1"
    color="colors.gray08"
    mb="8px"
    sx={{ fontSize: '14px', fontWeight: 600, lineHeight: '18px' }}
  >
    {title}
    {required && <span style={{ color: palette.colors.error03 }}>*</span>}
  </Typography>
);

FieldLabel.defaultProps = {
  required: false,
};

const schema = yup
  .object()
  .shape({
    type: yup.string().required(),
    callDate: yup.date().required(),
    callTime: yup.date().required(),
    name: yup.string().required(),
    expertName: yup.string(),
    analystName: yup.string(),
    expertPerspective: yup.string(),
    expertNetwork: yup.string(),
    expertEmploymentHistory: yup.string().trim(),
    expertConsultingSummary: yup.string().trim(),
  })
  .required();

const AddCall = () => {
  const { me } = useMe();
  const navigate = useNavigate();
  const { refetchCompletedCalls, refetchReviewedCalls, refetchTranscript } = useContext(AppContext);
  const [openSidebar, setOpenSidebar] = useState(false);
  const [droppedFile, setDroppedFile] = useState<File | null>(null);
  const { data: { allNetworks } = {} } = useQuery<{
    allNetworks: NetworksType[];
  }>(ALL_NETWORKS_QUERY);

  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { isSubmitting, dirtyFields },
  } = useForm<IFormInput>({
    reValidateMode: 'onChange',
    mode: 'onChange',
    criteriaMode: 'all',
    // @ts-ignore
    resolver: yupResolver(schema),
    defaultValues: {
      type: 'call',
      callDate: null,
      callTime: null,
      name: '',
      expertName: '',
      analystName: '',
      expertPerspective: '',
      expertNetwork: 'Default',
      expertEmploymentHistory: '',
      expertConsultingSummary: '',
    },
  });

  const selectedType = watch('type');
  const callDateField = watch('callDate');
  const callTimeField = watch('callTime');

  useEffect(() => {
    if (selectedType === 'document') {
      setValue('callDate', dayjs());
      setValue('callTime', dayjs());
    } else {
      setValue('callDate', null);
      setValue('callTime', null);
    }
    setDroppedFile(null);
  }, [selectedType, setValue]);

  useEffect(() => {
    if (!openSidebar) {
      reset();
      setDroppedFile(null);
    }
  }, [openSidebar, reset]);

  const handleFileDrop = useCallback(
    (file: File) => {
      setDroppedFile(file);
    },
    [setDroppedFile],
  );

  const handleDeleteFile: React.MouseEventHandler<HTMLButtonElement> = event => {
    event.stopPropagation();
    setDroppedFile(null);
  };

  const toggleDrawer = (open: boolean) => () => {
    setOpenSidebar(open);
  };

  const [createCallMutation] = useMutation(CALL_MUTATION);
  const [uploadCallFileMutation] = useMutation(UPLOAD_CALL_FILE_MUTATION);

  const sortedNetworks = allNetworks
    ? [...allNetworks].sort((a, b) => {
      if (a.value === 'Default') return -1;
      if (b.value === 'Default') return 1;
      return a.value.localeCompare(b.value);
    }) : [];

  const onSubmit = async (data: IFormInput) => {
    if (!droppedFile) return;

    const {
      type, callDate, callTime, name,
    } = data;

    let {
      expertName,
      expertNetwork,
      analystName,
      expertPerspective,
      expertEmploymentHistory,
      expertConsultingSummary,
    } = data;

    if (type === 'meeting' || type === 'document') {
      expertNetwork = 'Default';
      expertName = '';
      analystName = '';
      expertPerspective = '';
      expertEmploymentHistory = '';
      expertConsultingSummary = '';
    }

    // IMPORTANT STEP: Convert the call dates to Dayjs objects cause the form returns them as Date objects
    const date = dayjs(callDate);
    const time = dayjs(callTime);

    const datetime = date.hour(time.hour()).minute(time.minute()).second(time.second());

    const datetimeInUserTimezone = datetime.tz(me.timezone);

    const callData = {
      type,
      datetime: datetimeInUserTimezone.toISOString(),
      name,
      expertName,
      analystName,
      expertPerspective,
      network: allNetworks?.find(({ value }) => value === expertNetwork)?.key,
      expertEmploymentHistory,
      expertConsultingSummary,
      status: 'completed', // Important to set the status to COMPLETED
      inSample: true,
      inResearch: true,
    };

    let callUuid: string;
    let transcriptUuid: string;

    try {
      const { data: callResponse } = await createCallMutation({
        variables: { input: callData },
      });

      callUuid = callResponse.call.call.uuid;
      transcriptUuid = callResponse.call.call.transcript.uuid;
    } catch (error: any) {
      console.error('Error creating call:', error);
      return;
    }

    try {
      const variables: { callUuid: string; file: File; fileType?: string } = {
        callUuid,
        file: droppedFile,
        ...(type === 'document' && { fileType: 'notes' }),
      };

      const { data: uploadFileResponse } = await uploadCallFileMutation({ variables });
    } catch (error: any) {
      console.error('Error uploading file:', error?.message || error);
    }

    setOpenSidebar(false);
    refetchCompletedCalls?.();
    refetchReviewedCalls?.();
    navigate(paths.home.reverse({ transcriptId: transcriptUuid }));
    refetchTranscript?.();
    // window.location.href = paths.home.reverse({ transcriptId: transcriptUuid });
  };

  return (
    <Box display="flex">
      <AddButton onClick={toggleDrawer(true)}>
        <AddCircleIcon />
      </AddButton>
      <DrawerContainer
        anchor="right"
        open={openSidebar}
        onClose={toggleDrawer(isSubmitting)}
      >
        <ScrollContainer>
          <Box display="flex" flexDirection="column">
            <Box display="flex" alignItems="center" sx={{ padding: '20px 36px' }}>
              <CloseButton onClick={toggleDrawer(false)}>
                <CloseIcon />
              </CloseButton>
              <Typography variant="h3" color="colors.gray10" sx={{ fontSize: '24px' }}>
                Import Content
              </Typography>
            </Box>
            <Divider flexItem sx={{ borderColor: 'colors.gray03' }} />
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box display="flex" flexDirection="column" flexGrow={1} sx={{ padding: '24px 32px' }}>
                <TargetBox
                  onDrop={handleFileDrop}
                  onDelete={handleDeleteFile}
                  droppedFile={droppedFile}
                  callType={selectedType}
                />
                <Grid container rowSpacing="16px" columnSpacing="32px">
                  <Grid item xs={12}>
                    <FieldLabel title="Type" />
                    <Controller
                      name="type"
                      control={control}
                      defaultValue="call"
                      render={({ field }) => (
                        <RadioGroup row aria-labelledby="row-radio-buttons-group" defaultValue="call" {...field}>
                          <FormControlLabel value="call" control={<CustomRadio disableRipple />} label="Expert Call" />
                          <FormControlLabel value="meeting" control={<CustomRadio disableRipple />} label="Meeting" />
                          <FormControlLabel value="document" control={<CustomRadio disableRipple />} label="Document" />
                        </RadioGroup>
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FieldLabel title="Date" required />
                    <Controller
                      control={control}
                      name="callDate"
                      render={({ field: { onChange, value, ref } }) => (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePickerField value={value} onChange={onChange} inputRef={ref} views={['year', 'month', 'day']} format="MM/DD/YYYY" disableFuture />
                        </LocalizationProvider>
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FieldLabel title="Time" required />
                    <Controller
                      control={control}
                      name="callTime"
                      render={({ field: { onChange, value, ref } }) => (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <TimePickerField value={value} onChange={onChange} inputRef={ref} views={['hours', 'minutes']} />
                        </LocalizationProvider>
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FieldLabel title="Topic" required />
                    <Controller
                      control={control}
                      name="name"
                      render={({
                        field: {
                          onChange, value, name, ref,
                        }, fieldState: { error },
                      }) => (
                        <TextInputField
                          fullWidth
                          inputRef={ref}
                          name={name}
                          value={value}
                          error={!!error}
                          onChange={onChange}
                          variant="outlined"
                          placeholder="eg. Geo political effects"
                        />
                      )}
                    />
                  </Grid>
                  {selectedType === 'call' && (
                    <>
                      <Grid item xs={6}>
                        <FieldLabel title="Expert Name" />
                        <Controller
                          control={control}
                          name="expertName"
                          render={({
                            field: {
                              onChange, value, name, ref,
                            }, fieldState: { error },
                          }) => (
                            <TextInputField
                              fullWidth
                              inputRef={ref}
                              name={name}
                              value={value}
                              error={!!error}
                              onChange={onChange}
                              variant="outlined"
                              placeholder="eg. John Doe"
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FieldLabel title="Analyst Name" />
                        <Controller
                          control={control}
                          name="analystName"
                          render={({
                            field: {
                              onChange, value, name, ref,
                            }, fieldState: { error },
                          }) => (
                            <TextInputField
                              fullWidth
                              inputRef={ref}
                              name={name}
                              value={value}
                              error={!!error}
                              onChange={onChange}
                              variant="outlined"
                              placeholder="eg. John Doe"
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FieldLabel title="Expert Perspective" />
                        <Controller
                          control={control}
                          name="expertPerspective"
                          render={({
                            field: {
                              onChange, value, name, ref,
                            }, fieldState: { error },
                          }) => (
                            <TextInputField
                              fullWidth
                              inputRef={ref}
                              name={name}
                              value={value}
                              error={!!error}
                              onChange={onChange}
                              variant="outlined"
                              placeholder="eg. Former"
                            />
                          )}
                        />
                      </Grid>
                    </>
                  )}
                  {selectedType === 'call' && (
                    <>
                      <Grid item xs={6}>
                        <FieldLabel title="Expert Network" />
                        <Controller
                          control={control}
                          name="expertNetwork"
                          defaultValue="Default"
                          render={({ field }) => (
                            <Select
                              {...field}
                              value={field.value}
                              onChange={e => {
                                field.onChange(e);
                              }}
                              input={<SelectInput />}
                              inputProps={{ 'aria-label': 'Without label' }}
                              IconComponent={ExpandMoreIcon}
                              MenuProps={MenuProps}
                              renderValue={selected => (
                                <Box display="flex" alignItems="center">
                                  {selected}
                                </Box>
                              )}
                            >
                              {sortedNetworks?.map(network => (
                                <MenuItem sx={{ padding: '6px 12px' }} key={network.key} value={network.value}>
                                  <NameContainer variant="body1" color="colors.gray08">
                                    {network.value}
                                  </NameContainer>
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FieldLabel title="Employment History" />
                        <Controller
                          control={control}
                          name="expertEmploymentHistory"
                          render={({
                            field: {
                              onChange, value, name, ref,
                            }, fieldState: { error },
                          }) => (
                            <TextInputField
                              fullWidth
                              minRows={6}
                              multiline
                              inputRef={ref}
                              name={name}
                              value={value}
                              error={!!error}
                              onChange={onChange}
                              variant="outlined"
                              placeholder="Enter the employment history..."
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FieldLabel title="Expert Consulting Summary" />
                        <Controller
                          control={control}
                          name="expertConsultingSummary"
                          render={({
                            field: {
                              onChange, value, name, ref,
                            }, fieldState: { error },
                          }) => (
                            <TextInputField
                              fullWidth
                              minRows={6}
                              multiline
                              inputRef={ref}
                              name={name}
                              value={value}
                              error={!!error}
                              onChange={onChange}
                              variant="outlined"
                              placeholder="Enter the summary..."
                            />
                          )}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              </Box>
            </form>
          </Box>
        </ScrollContainer>
        <FooterBlock>
          <ImportFullButton
            variant="contained"
            fullWidth
            onClick={handleSubmit(onSubmit)}
            disabled={
              isSubmitting
              || callDateField === null
              || callTimeField === null
              || !dirtyFields.name
              || !droppedFile
            }
          >
            <CheckCircleIcon style={{ marginRight: '12px' }} />
            Import
          </ImportFullButton>
        </FooterBlock>
        {isSubmitting && (
          <BlurBox>
            <CircularProgress sx={{ color: palette.colors.gray03 }} size={80} thickness={2} />
            <Box color="colors.gray05" mt="16px" sx={{ fontSize: '16px' }}>
              Uploading in process. Please do not close the window.
            </Box>
          </BlurBox>
        )}
      </DrawerContainer>
    </Box>
  );
};

export default AddCall;
